import { AuthApis } from '../api/auth-api';
import { UserApis } from '../api/user-api';

import { setLoaderValue } from './master-actions';

export const login = (values, onSuccessCallback, onErrorCallback) => () => {
  AuthApis.userLogin(values).then((response) => onSuccessCallback && onSuccessCallback(response)).catch((error)=>{
    onErrorCallback(error)
  });
};

export const signup = (values, onSuccessCallback) => () => {
  AuthApis.registerUser(values).then((response) => onSuccessCallback && onSuccessCallback());
};

export const isEmailExist = (email, callBack) => (dispatch) => {
  dispatch(setLoaderValue(true));
  UserApis.checkEmailExist(email).then((response) => {
    callBack && callBack(response);
    dispatch(setLoaderValue(false));
  });
};

export const sendEmail = (email, callBack) => (dispatch) => {
  dispatch(setLoaderValue(true));
  AuthApis.sendEmailToUser(email)
    .then((response) => {
      callBack && callBack(response);
      dispatch(setLoaderValue(false));
    })
    .catch((error) => {
      console.log('send email error: ', error);
      dispatch(setLoaderValue(false));
      return error;
    });
};

export const verifiedToken = (token, callBack, errorCallBack) => (dispatch) => {
  dispatch(setLoaderValue(true));
  AuthApis.mailTokenVerified(token)
    .then((response) => {
      callBack && callBack(response);
      dispatch(setLoaderValue(false));
    })
    .catch((error) => {
      errorCallBack && errorCallBack(error);

      dispatch(setLoaderValue(false));
      return error;
    });
};

export const resetPassword = (bodyData, callBack) => (dispatch) => {
  dispatch(setLoaderValue(true));
  AuthApis.resetUserOldPassword(bodyData)
    .then((response) => {
      callBack && callBack(response);
      dispatch(setLoaderValue(false));
    })
    .catch((error) => {
      dispatch(setLoaderValue(false));
      return error;
    });
};
