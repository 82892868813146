import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Box, TextField, Grid, Stack, Link, Typography, Divider, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import LogoName from "../../../assets/logos/Logo.svg";
import Logo from "../../../components/common/Logo";

import SocialAuth from "../../../components/common/SocialAuth";
import CommonButton from "../../../components/common/Button";

import { login } from "../../../actions/authentication-actions";
import { getUserData } from "../../../actions/user-actions";
import AnimationImage from "../../../components/common/AnimationImage";
import { QuestionApis } from "../../../api/question-api";

const useStyles = makeStyles((theme) => ({
  bodyScroll: {
    [theme.breakpoints.down("900")]: {
      overflowY: "auto",
    }
  },
  divider: {
    display: "none",
    [theme.breakpoints.down("900")]: {
      display: "block",
      border: "1px solid #009A4E",
      width: "100%",
      marginTop: "10px",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  const emailId = localStorage.getItem("emailID");

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: emailId ? emailId : "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      localStorage.setItem("currentUserEmail", values.email);
      dispatch(
        login(values, (result) => {
          if (result && result?.data?.authToken?.token) {
            localStorage.setItem("LoginToken", result?.data?.authToken?.token);
            localStorage.setItem("refID", result?.data.user.id);
            dispatch(getUserData(values.email));
            QuestionApis.getAllQuestions(result?.data?.user.id).then((res) => {
              navigate(`/dashboard/answer-question/${res?.data[0].id}`, { replace: true });
              enqueueSnackbar("Login Successful", {
                anchorOrigin: {
                  horizontal: "right",
                  vertical: "top",
                },
                variant: "success",
              });
            });
            resetForm();
          } else {
            enqueueSnackbar("Invalid Email Or Password", {
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
              variant: "error",
            });
          }
        }, (error)=>{
          enqueueSnackbar("Invalid Email Or Password", {
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            variant: "error",
          });
        })
      );
    },
  });

  const { errors, touched, resetForm } = formik;

  return (
    <Box>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ overflowY: "scroll", height: "100vh" }} className={classes.bodyScroll}>
            <Box sx={{ marginX: 10, paddingTop: 5 }} className="formlayout">
              <Box className="logoDiv">
                <Logo LogoImg={LogoName} />
                <Box className="links">
                  <Link className="link_login_signup" to="/signup" component={RouterLink}>
                    Sign Up
                  </Link>
                </Box>
              </Box>
            </Box>
            <Divider className={classes.divider} />

            <Box sx={{ marginX: 10, paddingBottom: 5 }} className="formlayout">
              <Box>
                <Box sx={{ mt: 7, mb: 5 }} className="head_login_signup">
                  <Typography className="login_signup">Log in.</Typography>

                  <Link href="#" className="side_login_signup" to="/signup" component={RouterLink}>
                    Sign up
                  </Link>
                </Box>

                <Box>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                      <Stack spacing={4}>
                        <TextField
                          value={formik.values.email}
                          name="email"
                          color="success"
                          onChange={formik.handleChange}
                          fullWidth
                          type="email"
                          label="Your Email"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />

                        <TextField
                          value={formik.values.password}
                          name="password"
                          color="success"
                          onChange={formik.handleChange}
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          label="Your Password"
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Box sx={{ mt: 5, mb: 5 }} className="head_login_signup">
                            <Link
                              color="#b0b0b0"
                              underline="hover"
                              sx={{
                                cursor: "pointer",
                                textAlign: "end",
                              }}
                              onClick={() => forgotPassword()}
                            >
                              Forgot Password?
                            </Link>

                        </Box>
                        <CommonButton
                          variant={"contained"}
                          type={"submit"}
                          style={{
                            padding: 1.5,
                            bgcolor: "#009A4E",
                            marginBottom: "20px !important",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#009A4E",
                            },
                          }}
                        >
                          Login
                        </CommonButton>
                        <SocialAuth />
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Box>
                <Box className="termAndPrivacyLink">
                  <Link href="#" target="_blank" className="termsLink" to="/terms-condition" component={RouterLink}>
                      Terms & Condition
                  </Link>
                  <Link href="#" target="_blank" className="termsLinkPrivacy" to="/privacy-policy" component={RouterLink}>
                      Privacy Policy
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6}>
          <AnimationImage />
        </Grid>
      </Grid>
    </Box>
  );
}
