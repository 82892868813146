import { request } from '../utils/axios';
import { SERVER_URL } from '../App.config';


export const getAllRaces = () => {
    return request(SERVER_URL + '/races', 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

export const getAllCountries = () => {
    return request(SERVER_URL + '/countries', 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
}

export const CommonApis = {
    getAllRaces,
    getAllCountries,
}
