import { SET_COUNTRIES } from "../actions/actionType";

const initialState = {
  countries: [],
};

export default function countryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };

    default:
      return state;
  }
}
