import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import { getQuestionResponse } from "../../../actions/questions-actions";

const useStyles = makeStyles((theme) => ({
  selectedQuestion: {
    [theme.breakpoints.down("sm")]: {
      padding: "35px",
    }
  },

  selectQuestionBox: {
    background: "#FFF",
    height: "auto",
    padding: "20px !important",
    borderRadius: "15px 15px 15px 15px ",
    borderRight: "7px solid #EFC7A4",
    borderLeft: "7px solid #EFC7A4",
    marginBottom: "20px !important",
    cursor: "pointer",
  },

  question: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    marginBottom: 5,
    overflowWrap: "break-word",
    textAlign: "justify",
    wordSpacing: "6px",
  },
  description: {
    color: "#333333",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    marginBottom: 10,
  },
  answerTime: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "14px",
    color: "#2F80ED",
  },

  backLink: {
    textDecoration: "none",
    color: "#2F80ED",
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: 600,
  },
}));

export default function SelectQuestion() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userID = localStorage.getItem("refID");

  const { searchedQuestions, allQuestions } = useSelector((state) => state.questionReducer);

  const setQuestionToReadQuestion = (question) => {
    dispatch(
      getQuestionResponse(question?.id, userID, (response) => {
        navigate(`/dashboard/answer-question/${question?.id}`, {
          state: { answer: response.data, question: question },
        });
      })
    );
  };

  return (
    <>
      <Box sx={{ padding: 10, maxWidth: 900 }} className={classes.selectedQuestion} >
      {searchedQuestions.length > 0 ? (
        searchedQuestions?.map((question, index) => (
          (
            (question?.isActive) && <Box className={classes.selectQuestionBox} key={index} onClick={() => setQuestionToReadQuestion(question)}>
              <Box className={classes.question}>{question?.title}</Box>

              {question?.description &&
                <Box className={classes.description}>{question?.description.substring(0, 234)}</Box>
              }

              <Box className={classes.answerTime}>
                {question?.categoryName} - {question?.subCategoryName}
              </Box>
            </Box>
          )
        ))
      ) : (
          <Box className={classes.selectQuestionBox} key={null}>
            <Box className={classes.question}>Questions Not Available</Box>

            <Link className={classes.backLink} to={`/dashboard/answer-question/${allQuestions[0]?.id}`}>
              Back To Answer Question
            </Link>
          </Box>
      )}
        
     </Box>
    </>
  );
}
