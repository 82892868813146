import { SERVER_URL } from '../App.config';
import { request } from '../utils/axios';

const registerUser = (userData) => {
    let bodyFormData = {
        email: userData.email,
        name: userData.name,
        password: userData.password
    }
    return request(SERVER_URL + '/register', 'post', bodyFormData)
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

const userLogin = (userData) => {
    return request(SERVER_URL + '/login', 'post', userData)
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

function sendEmailToUser(email) {
    return request(SERVER_URL + '/forgot-password', 'post', email)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log('send email error: ', error);
            throw error;
        });
}

function mailTokenVerified(token) {
    return request(SERVER_URL + `/verify-email/${token}`, 'put')
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
}

function resetUserOldPassword(requestBody) {
    console.log('requestBody: ', requestBody);
    return request(SERVER_URL + '/reset-password', 'post', requestBody)
        .then((response) => response.data)
        .catch((error) => {
            console.log('reset password error: ', error);
            throw error;
        });
}

export const AuthApis = {
    registerUser,
    userLogin,
    sendEmailToUser,
    mailTokenVerified,
    resetUserOldPassword,
}