import { SERVER_URL } from '../App.config';
import { request } from '../utils/axios';

function setImageVideoMedia(bodyFormData) {
  return request(SERVER_URL + '/media/upload-doc', 'post', bodyFormData)
    .then((response) => response)
    .catch((error) => {
      console.log('set image error: ', error);
      throw error;
    });
}

function getMediaURL(requestBody) {
  return request(SERVER_URL + '/media/getImage', 'post', requestBody)
    .then((mediaUrl) => mediaUrl)
    .catch((error) => {
      console.log('get media  error: ', error);
      throw error;
    });
}

export const MediaApis = {
  setImageVideoMedia,
  getMediaURL,
}