import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { Box, TextField, Grid, Stack, Link, Typography, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import LogoName from "../../../assets/logos/Logo.svg";
import Logo from "../../../components/common/Logo";

import CommonButton from "../../../components/common/Button";

import AnimationImage from "../../../components/common/AnimationImage";
import { useRef } from "react";
import { isEmailExist, sendEmail } from "../../../actions/authentication-actions";

const useStyles = makeStyles((theme) => ({
  divider: {
    display: "none",
    [theme.breakpoints.down("900")]: {
      display: "block",
      border: "1px solid #009A4E",
      width: "100%",
      marginTop: "10px",
    },
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(
        isEmailExist(values.email, (response) => {
          if (response.length > 0) {
            dispatch(
              sendEmail({ email: values.email }, (response) => {
                enqueueSnackbar("Check Your Mail", {
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                  },
                  variant: "success",
                });
              })
            );
            resetForm();
          } else {
            enqueueSnackbar("Email Not Exist", {
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
              variant: "error",
            });
          }
        })
      );
    },
  });

  const { errors, touched, resetForm } = formik;

  return (
    <Box>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ overflowY: "scroll", height: "100vh" }}>
            <Box sx={{ marginX: 10, paddingY: 5 }} className="formlayout">
              <Box className="logoDiv">
                <Logo LogoImg={LogoName} />
                <Box className="links">
                  <Link className="link_login_signup" to="/" component={RouterLink}>
                    Login
                  </Link>
                </Box>
              </Box>
              <Divider className={classes.divider} />

              <Box>
                <Box sx={{ mt: 7, mb: 5 }} className="head_login_signup">
                  <Typography className="login_signup">Forgot Password ?</Typography>
                </Box>

                <Box>
                  <FormikProvider value={formik}>
                    <Form ref={form} onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                      <Stack spacing={4}>
                        <TextField
                          value={formik.values.email}
                          name="email"
                          color="success"
                          onChange={formik.handleChange}
                          fullWidth
                          type="email"
                          label="Your Email"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />

                        <CommonButton
                          variant={"contained"}
                          type={"submit"}
                          style={{
                            padding: 1.5,
                            bgcolor: "#009A4E",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#009A4E",
                            },
                          }}
                        >
                          Send Email
                        </CommonButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6}>
          <AnimationImage />
        </Grid>
      </Grid>
    </Box>
  );
}
