import { CategorySubcategoryApis } from "../api/category-subcategory-api";
import { UserApis } from "../api/user-api";
import { SET_TOPICS, SET_SUBCATEGORIES } from "./actionType";
import { setLoaderValue } from "./master-actions";

export const setTopic = (topics) => ({
  type: SET_TOPICS,
  topics
});


export const setSubCategories = (subCategories) => ({
  type: SET_SUBCATEGORIES,
  subCategories
});


export const setTopics = () => (dispatch) => {
  dispatch(setLoaderValue(true));
  CategorySubcategoryApis.getCategories().then((result) => {
    dispatch(setTopic(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const setSubcategory = () => (dispatch) => {
  dispatch(setLoaderValue(true));
  CategorySubcategoryApis.getSubcategories().then((result) => {
    dispatch(setSubCategories(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const topicPreferences = (topicPreference, onSuccessCallback) => (dispatch) => {
  dispatch(setLoaderValue(true));

  UserApis.updateUserPreferences(topicPreference).then((result) => {
    onSuccessCallback && onSuccessCallback(result);
    dispatch(setLoaderValue(false));
  });
};
