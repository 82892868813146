import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Box, TextField, Grid, Stack, Link, Typography, Divider, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import LogoName from "../../assets/logos/Logo.svg";
import Logo from "../../components/common/Logo";

import AnimationImage from "../../components/common/AnimationImage";

const useStyles = makeStyles((theme) => ({
  bodyScroll: {
    [theme.breakpoints.down("900")]: {
      overflowY: "auto",
    }
  },
  divider: {
    display: "none",
    [theme.breakpoints.down("900")]: {
      display: "block",
      border: "1px solid #009A4E",
      width: "100%",
      marginTop: "10px",
    },
  },
}));

export default function TermsCondition() {
  const classes = useStyles();
  const navigate = useNavigate();
 
 
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.bodyScroll}>
            <Box sx={{ marginX: 10, paddingTop: 5 }} className="formlayout">
              <Box className="logoDiv">
                <Logo LogoImg={LogoName} />    
                <Box className="links">
                  <Link className="link_login_signup" to="/" component={RouterLink}>
                    Go to application
                  </Link>
                </Box>           
              </Box>
            </Box>
          </Box>
          </Grid>
          <Grid item lg={1} md={1}></Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Box sx={{ marginX: 10, paddingBottom: 5, marginTop: 10 }} className="formlayout">
                <Box>
                  <Box className="head_login_signup">
                    <Typography className="termsH1">Terms of Service for MindTap</Typography>
                  </Box>
                  <Box className="head_login_signup">
                    <Typography className="termsH2">Effective date: Jan, 1st 2023</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    Welcome to MindTap (the "Service"). These Terms of Service (the "Agreement") set forth
                    the terms and conditions governing your use of the Service. Please read this Agreement
                    carefully before using the Service. By using the Service, you agree to be bound by this
                    Agreement. If you do not agree to be bound by this Agreement, you may not use the
                    Service.
                    </Typography>
                  </Box>
                  <Box className="head_login_signup">
                    <Typography className="termsH3">1. Use of the Service</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    Subject to your compliance with this Agreement, MindTap grants you a limited,
                    non-exclusive, non-transferable, and revocable license to use the Service for your
                    personal, non-commercial use.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    You are responsible for providing all equipment, software, and internet access
                    necessary to use the Service. You agree to use the Service only for lawful purposes and
                    in accordance with this Agreement.
                    </Typography>
                  </Box>

                  <Box className="head_login_signup">
                    <Typography className="termsH3">2. User Content</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    You retain all rights in any content that you post, upload, or otherwise submit to the
                    Service ("User Content"). By submitting User Content to the Service, you grant MindTap
                    a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use,
                    reproduce, distribute, prepare derivative works of, display, and perform the User Content
                    in connection with the Service.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    You represent and warrant that: (i) you own or have the necessary rights to submit the
                    User Content to the Service; (ii) the User Content does not violate any law or infringe any
                    third-party right, including any intellectual property or privacy right; and (iii) you will not
                    submit User Content that is defamatory, obscene, or otherwise objectionable.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    MindTap may, in its sole discretion, remove or disable access to any User Content for
any reason, including if MindTap determines that the User Content violates this
Agreement.
                    </Typography>
                  </Box>



                  <Box className="head_login_signup">
                    <Typography className="termsH3">3. Intellectual Property</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    The Service and its entire contents, features, and functionality (including but not limited
to all information, software, text, displays, images, video, and audio) are owned by
MindTap, its licensors, or other providers of such material and are protected by United
States and international copyright, trademark, patent, trade secret, and other intellectual
property or proprietary rights laws.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsTextInner">
                    - You may not reproduce, distribute, modify, create derivative works of, publicly display,
publicly perform, republish, download, store, or transmit any of the material on our
Service, except as follows:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsTextInner">
                    - Your computer may temporarily store copies of such materials in RAM incidental
to your accessing and viewing those materials.
                    </Typography>
                  </Box>

                  <Box>
                    <Typography className="termsTextInner">
                    - You may store files that are automatically cached by your Web browser for
display enhancement purposes.
                    </Typography>
                  </Box>

                  <Box>
                    <Typography className="termsText">
                    You may print or download one copy of a reasonable number of pages of the
Service for your own personal, non-commercial use and not for further
reproduction, publication, or distribution.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    If we provide desktop, mobile, or other applications for download, you may download a
single copy to your computer or mobile device solely for your own personal,
non-commercial use, provided you agree to be bound by our end user license agreement
for such applications.
                    </Typography>
                  </Box>
                </Box>


                <Box className="head_login_signup">
                    <Typography className="termsH3">4. Disclaimer of Warranties</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    The Service is provided "AS IS" and "AS AVAILABLE" without warranty of any kind, either
express or implied, including but not limited to the implied warranties of merchantability,
fitness for a particular purpose, non-infringement, or availability of the Service.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    MindTap does not warrant that the Service will be uninterrupted or error-free, that
defects will be corrected, or that the Service or the servers that make it available are free
of viruses or other harmful components.
                    </Typography>
                  </Box>
                 

                  <Box className="head_login_signup">
                    <Typography className="termsH3">5. Limitation of Liability</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText lastTermsText">
                    In no event will MindTap, its affiliates, licensors, service providers, employees, agents,
officers, or directors be liable.
                    </Typography>
                  </Box>                  
            </Box>
          </Grid>
      </Grid>
    </Box>
  );
}
