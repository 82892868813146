import {
  SET_ALL_QUESTIONS,
  REMOVE_QUESTION_FROM_ALL_QUESTIONS,
  SET_SELECTED_QUESTION,
  SET_CATEGORY_WISE_DATA,
  SET_CURRENT_USER_QUESTION,
  SET_CURRENT_USER_ANSWERS,
  SET_SEARCHED_QUESTIONS,
  QUESTIONS_MEDIA,
  SET_SIMILAR_QUESTIONS,
  SET_QUESTION_CATEGORY
} from "../actions/actionType";

const initialState = {
  selectedQuestion: {},
  allQuestions: [],
  relatedQuestions: [],
  userQuestion: [],
  userAnswers: [],
  searchedQuestions: [],
  questionsMedia: [],
  similarQuestions: [],
  questionCategory: {},
};

export default function questionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_QUESTIONS:
      return {
        ...state,
        allQuestions: action.allQuestions,
      };

    case SET_SELECTED_QUESTION:
      return {
        ...state,
        selectedQuestion: action.selectedQuestion,
      };

    case SET_CATEGORY_WISE_DATA:
      return {
        ...state,
        relatedQuestions: action.relatedQuestions,
      };

    case REMOVE_QUESTION_FROM_ALL_QUESTIONS:
      return {
        ...state,
        allQuestions: [
          ...state.allQuestions.filter(
            (deleteQuestion) => deleteQuestion?.id !== action.currentQuestion?.id
          ),
        ],
      };

    case SET_CURRENT_USER_QUESTION:
      return {
        ...state,
        userQuestion: action.userQuestion,
      };

    case SET_CURRENT_USER_ANSWERS:
      return {
        ...state,
        userAnswers: action.userAnswers,
      };

    case SET_SEARCHED_QUESTIONS:
      return {
        ...state,
        searchedQuestions: action.searchedQuestions,
      };

    case QUESTIONS_MEDIA:
      return {
        ...state,
        questionsMedia: action.questionsMedia,
      };

    case SET_SIMILAR_QUESTIONS:
      return {
        ...state,
        similarQuestions: action.similarQuestions,
      };

    case SET_QUESTION_CATEGORY:
      return {
        ...state,
        questionCategory: action.questionCategory,
      };

    default:
      return state;
  }
}
