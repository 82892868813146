import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  Paper,
  Chip,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { FormikProvider, useFormik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { getUserData, getUserPreferencesData } from "../../actions/user-actions";
import { setTopics, topicPreferences, setSubcategory } from "../../actions/topic-action";
import CommonButton from "../common/Button";
import _ from 'underscore';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 80,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },

  subCategoryBox: {
    paddingTop: 20,
  },

  textField: {
    backgroundColor: "#FFF",
  },

  btn: {
    width: 270,
    height: 48,
    backgroundColor: "#009A4E !important",
    textTransform: "none",
    marginTop: "24px !important",
    "&:hover": {
      backgroundColor: "#009A4E",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  chips: {
    alignItems: "center",
    minHeight: "70px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  topicName: {
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  mainChips: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },

  activeChips: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
    backgroundColor: "#009A4E !important",
    color: "#ffffff !important",
  },

  errorMessage: {
    color: "#FF0000",
  },

  labelCSS: {
    fontFamily: "Montserrat",
    fontSize: "25px",
    fontWeight: 900,
    color: "#000000 !important",
    paddingBottom: "5px",
  },

  autoCompleteCSS: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 1px rgba(0,0,0,0.05)",
    "& .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid transparent",
      outline: "1px solid transparent ",
    },
  },
}));

export default function QuestionPreferences() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { topics, subCategories } = useSelector((state) => state.topicReducer);
  const { userData, userPreferencesData } = useSelector((state) => state.userReducer);

  const [topicPreference, setTopicPreference] = useState([]);

  const userID = localStorage.getItem("refID");
  const currentUserEmail = localStorage.getItem("currentUserEmail");

  const topicSchema = Yup.object().shape({
    categories: Yup.array().min(1, "Field Required").required("Field Required"),
  });

  useEffect(() => {
    if (userPreferencesData && userPreferencesData.length && topics) {
      let temp = [];
      userPreferencesData.map((currentObj) => {
        temp.push({ category: currentObj.categoryname, categoryid: currentObj.categoryid, subcategory: currentObj.subcategoryname, subcategoryid: currentObj.subcategoryid  })
      })
      setTopicPreference(temp);
      let selectedCategories = [];
      for (let i = 0; i < topics.length; i++) {
        for (let j = 0; j < temp.length; j++) {
          if (topics[i].categoryid === temp[j].categoryid) {
            selectedCategories.push(topics[i]);
            break;
          }
        }
      }
      formik.setFieldValue("categories", selectedCategories);
    } else {
      setTopicPreference([]);
    }
  }, [userData, topics, userPreferencesData]);

  useEffect(() => {
    dispatch(setTopics());
    dispatch(getUserPreferencesData());
    dispatch(setSubcategory());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      categories: [],
    },
    validationSchema: topicSchema,
    onSubmit: (submitProps) => {
      dispatch(
        topicPreferences({ topicPreference: topicPreference }, () => {
          dispatch(getUserPreferencesData());

          enqueueSnackbar("Preferences Stored Successfully ", {
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            variant: "success",
          });
        })
      );
      submitProps.setSubmitting(false);
    },
  });

  const checkSelection = (topic, subtopic) => {
    let selected = false;
    for (let i = 0; i < topicPreference.length; i++) {
      if (topicPreference[i].categoryid === topic.categoryid && topicPreference[i].subcategoryid === subtopic.id) {
        selected = true;
      }
    }
    return selected;
  };

  const chipClick = (topic, subtopic) => {
    setTopicPreference(
      checkSelection(topic, subtopic)
      ? topicPreference.filter((item) => !(item.categoryid === topic.categoryid && item.subcategoryid === subtopic.id))
      : [...topicPreference, { categoryid: topic.categoryid, category: topic.category, subcategory: subtopic.name, subcategoryid: subtopic.id }]
      );
    };

    const checkIfCategorySelected = (topic) => {
      let isSelected = false;
      topicPreference.map((item) => {
      if (item.categoryid === topic) {
        isSelected = true;
      }
    });
    return isSelected;
  };
  
  const checkIfSubcategorySelected = (topic, subtopic) => {
    let isSelected = false;
    topicPreference.map((item) => {
      if (item.categoryid === topic && item.subcategoryid === subtopic) {
        isSelected = true;
      }
    });
    return isSelected;
  };

  const fillTopicPreference = (value) => {
    let newValues = [];
    for (let j = 0; j < value.length; j++) {
      let tempSub = _.where(subCategories, { CategoryId: value[j].categoryid });
      if (!checkIfCategorySelected(value[j].categoryid)) {
        for (let i = 0; i < tempSub.length; i++) {
          newValues.push({
            categoryid: value[j].categoryid,
            subcategoryid: tempSub[i].id,
            category: value[j].category,
            subcategory: tempSub[i].name
          });
        }
      } else {
        for (let i = 0; i < tempSub.length; i++) {
          if (checkIfSubcategorySelected(value[j].categoryid, tempSub[i].id)) {
            newValues.push({
              categoryid: value[j].categoryid,
              category: value[j].category,
              subcategoryid: tempSub[i].id,
              subcategory: tempSub[i].name
            });
          }
        }
      }
    }
    setTopicPreference(newValues);
  };

  const popupIcon = formik?.values?.categories?.length === topics?.length ? <></> : undefined

  return (
    <Box className={classes.root}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}>
          <Box>
            <Autocomplete
              multiple
              options={topics && topics?.map((option) => option)}
              getOptionLabel={(option) => option.category ? option.category : ''}
              value={formik.values.categories}
              onChange={(event, value) => {
                event.stopPropagation();
                formik.setFieldValue("categories", value);
                fillTopicPreference(value);
              }}
              popupIcon={popupIcon}
              ChipProps={{ sx: { backgroundColor: "#009A4E ", color: "#FFF" } }}
              filterSelectedOptions={true}
              renderInput={(params) => (
                <FormControl
                  error={Boolean(formik.touched.categories && formik.errors.categories)}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel className={classes.labelCSS}>Select Categories</FormLabel>

                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={formik?.values?.categories.length === 0 ? "Categories" : ""}
                    color="success"
                    className={classes.autoCompleteCSS}
                    value={`${formik.values.categories}`}
                    name={`categories`}
                    sx={{ background: "#FFF" }}
                  />

                  <FormHelperText>{formik.touched.categories && formik.errors.categories}</FormHelperText>
                </FormControl>
              )}
            />
          </Box>

          <Box className={classes.subCategoryBox}>
            {formik?.values?.categories?.length > 0 &&
              formik?.values?.categories.map((category) => {
                let subcategory = _.where(subCategories, { CategoryId: category.categoryid } )
                return (
                  <Paper elevation={2} className={classes.chips} key={category.categoryid}>
                    <Typography
                      sx={{
                        color: "#2F80ED",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "17px",
                        fontFamily: "Montserrat",
                        padding: "10px",
                      }}
                    >
                      {category?.category}
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", mx: 1 }}>
                      {subcategory?.map((subtopic, index) =>  {
                        return (
                          <Chip
                            label={`${subtopic.name}`}
                            clickable={true}
                            onClick={() => chipClick(category, subtopic)}
                            key={index}
                            className={checkSelection(category, subtopic) ? classes.activeChips : classes.mainChips}
                            sx={{ margin: "5px" }}
                            value={subtopic.name}
                            name="Chips"
                          />
                          )
                        }
                      )}
                    </Box>
                    <Typography
                      sx={{
                        color: "#2F80ED",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "17px",
                        fontFamily: "Montserrat",
                        padding: "10px",
                        color: "#FF0000",
                        textAlign: "right",
                      }}
                    >
                      {!checkIfCategorySelected(category.categoryid) && "Minimum one selection required"}
                    </Typography>
                  </Paper>
                );
              })}

            <CommonButton variant="contained" className={classes.btn} type="submit" >
              Submit
            </CommonButton>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
