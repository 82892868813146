import {
  SET_ALL_QUESTIONS,
  REMOVE_QUESTION_FROM_ALL_QUESTIONS,
  SET_SELECTED_QUESTION,
  SET_CATEGORY_WISE_DATA,
  SET_CURRENT_USER_QUESTION,
  SET_CURRENT_USER_ANSWERS,
  SET_SEARCHED_QUESTIONS,
  SET_MEDIA_URL,
  QUESTIONS_MEDIA,
  SET_SIMILAR_QUESTIONS,
  SET_QUESTION_CATEGORY
} from './actionType';

import { setLoaderValue } from './master-actions';
import { MediaApis } from '../api/media-api';
import { QuestionApis } from '../api/question-api';
import { RatingApis } from '../api/rating-api';

export const setAllQuestions = (allQuestions) => ({
  type: SET_ALL_QUESTIONS,
  allQuestions
});

export const setSelectedQuestion = (selectedQuestion) => ({
  type: SET_SELECTED_QUESTION,
  selectedQuestion
});

export const removeQuestion = (currentQuestion) => ({
  type: REMOVE_QUESTION_FROM_ALL_QUESTIONS,
  currentQuestion
});

export const setCategoryWiseData = (relatedQuestions) => ({
  type: SET_CATEGORY_WISE_DATA,
  relatedQuestions
});

export const currentUserQuestion = (userAllQuestion) => ({
  type: SET_CURRENT_USER_QUESTION,
  userQuestion: userAllQuestion
});

export const currentUsersAnswers = (userAllAnswers) => ({
  type: SET_CURRENT_USER_ANSWERS,
  userAnswers: userAllAnswers
});

export const setSearchedQuestions = (searchedQuestions) => ({
  type: SET_SEARCHED_QUESTIONS,
  searchedQuestions
});

export const setQuestionsMedia = (questionsMedia) => ({
  type: QUESTIONS_MEDIA,
  questionsMedia
});

export const setSimilarQuestions = (similarQuestions) => ({
  type: SET_SIMILAR_QUESTIONS,
  similarQuestions
});

export const setQuestionCategory = (questionCategory) => ({
  type: SET_QUESTION_CATEGORY,
  questionCategory
});

export const setSelectedQuestionState = (selectedQuestion, userId) => (dispatch) => {
  dispatch(setSelectedQuestion(selectedQuestion?.data[0]));
  if(selectedQuestion?.data[0]?.id) {
    dispatch(categoryWiseData(selectedQuestion?.data[0]?.CategoryId, selectedQuestion?.data[0]?.id, userId));
  }
};

export const setQuestionsMediaByUserId = () => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.getAllQuestionsMedia().then((result) => {
    dispatch(setQuestionsMedia(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const getQuestions = (userID) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.getAllQuestions(userID).then((result) => {
    dispatch(setAllQuestions(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const responseToQuestion =
  (userID, value, currentQuestion, successCallback) => (dispatch) => {
    dispatch(setLoaderValue(true));
    QuestionApis.setUserAnswerResponse(userID, value, currentQuestion).then((response) => {
      dispatch(setSelectedQuestion({}));
      successCallback && successCallback();
    });
    dispatch(setLoaderValue(false));
  };
/* export const updateResponseToQuestion = (answerId, value, callback) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.updateUserAnswerResponse(answerId, value).then(() => {
    callback && callback();
  });
  dispatch(setLoaderValue(false));
}; */

export const skipRemind = (currentQuestion, userId, type) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.skipRemindQuestion(currentQuestion.id, userId, type).then(() => {
    dispatch(removeQuestion(currentQuestion));
  });
  dispatch(setLoaderValue(false));
};

export const reportCurrentQuestion =
  (currentQuestion, userId, type, value, callBack) => (dispatch) => {
    dispatch(setLoaderValue(true));
    QuestionApis.reportQuestion(currentQuestion.id, userId, type, value).then(
      () => callBack && callBack()
    );
    dispatch(setLoaderValue(false));
  };
export const User = (queToRemove) => (dispatch) => {
  dispatch(removeQuestion(queToRemove));
};

export const categoryWiseData = (categoryId, questionId, userId) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.currentCategory(categoryId, questionId, userId).then((result) => {
    dispatch(setCategoryWiseData(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const createQuestion = (values, userID, onSuccessCallback) => (dispatch) => {
  dispatch(setLoaderValue(true));
  if (values.media && values.media.length > 0) {
    let mediaObjects = [];
    for (let i = 0; i < values.media.length; i++) {
      let formData = new FormData();
      formData.append('file', values.media[i]);
      let mediaObjectPromise = MediaApis.setImageVideoMedia(formData);
      mediaObjects.push(mediaObjectPromise);
    }
    Promise.all(mediaObjects)
      .then((data) => {
        return data.map((item) => item.data);
      })
      .then((mediaArray) => {
        values.media = mediaArray;
        QuestionApis.createQuestion(values, userID).then((result) => {
          onSuccessCallback && onSuccessCallback(result);
          dispatch(setLoaderValue(false));
        });
      })
      .catch((error) => {
        dispatch(setLoaderValue(false));
      });
  } else {
    QuestionApis.createQuestion(values, userID).then((result) => {
      onSuccessCallback && onSuccessCallback(result);
      dispatch(setLoaderValue(false));
    });
  }
};

export const deleteQuestion = (values, queId, callBack) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.deleteQuestion(values, queId).then(() => {
    callBack && callBack();
    dispatch(setLoaderValue(false));
  });
};

export const userUpdateQuestion = (values, queID, userID, onSuccessCallback) => (dispatch) => {
  dispatch(setLoaderValue(true));
  if (values.media && values.media.length > 0) {
    console.log('values.media: ', values.media);
    console.log("inside if");
    let mediaObjects = [];
    console.log('values.media.length: ', values.media.length);
    for (let i = 0; i < values.media.length; i++) {
      if (values.media[i].otherDetail && values.media[i].otherDetail.ETag) {
        console.log("inner if");
        mediaObjects.push(values.media[i]);
      } else {
        console.log("inner else");
        let formData = new FormData();
        formData.append('file', values.media[i]);
        let mediaObjectPromise = MediaApis.setImageVideoMedia(formData);
        mediaObjects.push(mediaObjectPromise);
      }
    }
    Promise.all(mediaObjects)
      .then((data) => {
        return data.map((item) => {
          if(item.URL) {
            // this is in edit mode
            return item.otherDetail
          } else {
            /// this is in create mode
            return item.data
          }
        });
      })
      .then((mediaArray) => {
        values.media = mediaArray;
        QuestionApis.updateQuestion(values, queID, userID).then((result) => {
          onSuccessCallback && onSuccessCallback(result);
          dispatch(setLoaderValue(false));
        });
      })
      .catch((error) => {
        console.log('error: ', error);
        dispatch(setLoaderValue(false));
      });
  } else {
    QuestionApis.updateQuestion(values, queID, userID).then((result) => {
      onSuccessCallback && onSuccessCallback(result);
      dispatch(setLoaderValue(false));
    });
  }
};

export const userQuestions = (userID) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.getUserAllQuestion(userID).then((result) => {
    dispatch(currentUserQuestion(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const usersAnswers = (userID) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.getUsersAnswers(userID).then((result) => {
    dispatch(currentUsersAnswers(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const getQuestionResponse = (queID, userID, callBack) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.userQuestionResponse(queID, userID)
    .then((response) => callBack && callBack(response))
    .catch((error) => error);
  dispatch(setLoaderValue(false));
};
export const selectQuestionBySearch = (selectedOption, userID) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.getQuestionBasedOnOption(selectedOption, userID).then((questions) => {
    dispatch(setSearchedQuestions(questions.data));
    dispatch(setLoaderValue(false));
  });
};

export const setMedia = (imageVideoMedia, onSuccessCallback) => (dispatch) => {
  dispatch(setLoaderValue(true));
  MediaApis.setImageVideoMedia(imageVideoMedia)
    .then((mediaKey) => onSuccessCallback && onSuccessCallback(mediaKey))
    .catch((error) => error);
  dispatch(setLoaderValue(false));
};

export const ratingAverage = (queId) => (dispatch) => {
  dispatch(setLoaderValue(true));
  RatingApis.setRatingAverage(queId);
  dispatch(setLoaderValue(false));
};

export const getSimilarQuestions = (title, numberOfExamples) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.getSimilarQuestions(title, numberOfExamples).then((result) => {
    dispatch(setSimilarQuestions(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const getQuestionCategory = (title) => (dispatch) => {
  dispatch(setLoaderValue(true));
  QuestionApis.getQuestionCategory(title).then((result) => {
    dispatch(setQuestionCategory(result.data));
    dispatch(setLoaderValue(false));
  });
};
