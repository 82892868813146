import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";

import SignupSvg from "../../assets/images/SignupSvg.svg";
import LoginSvg from "../../assets/images/LoginSvg.svg";

const useStyles = makeStyles((theme) => ({
  bothImg: {
    backgroundPosition: "50%  50%",
    backgroundSize: "80% 80%",
    backgroundRepeat: "no-repeat",
    animationName: `$image`,
    animationDuration: "3s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "linear",
    [theme.breakpoints.down("900")]: {
      display: "none",
    },
  },

  "@keyframes image": {
    "0%": {
      backgroundImage: `URL(${LoginSvg})`,
    },

    "20%": {
      backgroundImage: `URL(${LoginSvg})`,
    },

    "40%": {
      backgroundImage: `URL(${LoginSvg})`,
    },
    "60%": {
      backgroundImage: `URL(${SignupSvg})`,
    },
    "80%": {
      backgroundImage: `URL(${SignupSvg})`,
    },
    "100%": {
      backgroundImage: `URL(${SignupSvg})`,
    },
  },
}));

export default function AnimationImage() {
  const classes = useStyles();

  return <Box sx={{ bgcolor: "#EFC7A4", height: "100vh" }} className={classes.bothImg}></Box>;
}
