import { SERVER_URL } from '../App.config';
import { request } from '../utils/axios';

export const getCategories = () => {
    return request(SERVER_URL + '/categories', 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

export const getSubcategories = () => {
    return request(SERVER_URL + '/subcategories', 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
}

export const getSubCategoriesByCategoryId = (categoryId) => {
    return request(SERVER_URL + `/categories/${categoryId}/subcategories`, 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

export const CategorySubcategoryApis = {
    getCategories,
    getSubcategories,
    getSubCategoriesByCategoryId
}