import React from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import "./App.css";

import DashboardLayout from "./components/layouts/DashboardLayout";

import Login from "./pages/authentication/Login";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import SignUp from "./pages/authentication/SignUp";
import Profile from "./pages/dashboard/Profile";
import AnswerQuestion from "./pages/dashboard/AnswerQuestion";
import UpdateProfile from "./components/homemenu/UpdateProfile";

import MyQuestion from "./components/homemenu/MyQuestion";
import CreateQuestion from "./pages/dashboard/CreateQuestion";
import SelectQuestion from "./pages/dashboard/SelectQuestion";
import QuestionPreferences from "./components/homemenu/QuestionPreferences";
import MyAnswers from "./components/homemenu/MyAnswers";

import ComingSoon from "../src/components/common/ComingSoon";
import Loader from "./components/common/Loader";

import { getUserData } from "./actions/user-actions";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import VerifyEmail from "./pages/authentication/VerifyEmail.js";

function App() {
  const dispatch = useDispatch();

  const loginToken = localStorage.getItem("LoginToken");
  const currentUserEmail = localStorage.getItem("currentUserEmail");

  if (loginToken) {
    dispatch(getUserData(currentUserEmail));
  }

  return (
    <>
      <div className="App">
        <Loader />

        <SnackbarProvider dense maxSnack={3}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/verify-email/*" element={<VerifyEmail />} />
              <Route path="/terms-condition" element={<TermsCondition />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/dashboard" element={<DashboardLayout />}>
                <Route path="profile" element={<Profile />}>
                  <Route path="update-profile" element={<UpdateProfile />} />
                  <Route path="notification-preferences" element={<ComingSoon title="Notification Preferences" />} />
                  <Route path="question-preferences" element={<QuestionPreferences />} />
                  <Route path="my-question" element={<MyQuestion />} />
                  <Route path="my-answers" element={<MyAnswers />} />
                </Route>
                <Route path="answer-question/:questionId" element={<AnswerQuestion />} />
                <Route path="create-question" element={<CreateQuestion />} />
                <Route path="select-question" element={<SelectQuestion />} />
              </Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </div>
    </>
  );
}

export default App;
