import { SET_RACES } from "../actions/actionType";

const initialState = {
  races: [],
};

export default function raceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RACES:
      return {
        ...state,
        races: action.races,
      };

    default:
      return state;
  }
}
