import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Box, TextField, Grid, Stack, Link, Typography, Divider, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";

import * as Yup from "yup";

import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

import LogoName from "../../../assets/logos/Logo.svg";
import Logo from "../../../components/common/Logo";
import CommonButton from "../../../components/common/Button";
import AnimationImage from "../../../components/common/AnimationImage";
import { resetPassword } from "../../../actions/authentication-actions";
import { UserApis } from "../../../api/user-api";

const useStyles = makeStyles((theme) => ({
  divider: {
    display: "none",
    [theme.breakpoints.down("900")]: {
      display: "block",
      border: "1px solid #009A4E",
      width: "100%",
      marginTop: "10px",
    },
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userId, setUserId] = useState();
  const forgotEmail = localStorage.getItem("forgotEmail");

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const LoginSchema = Yup.object().shape({
    newPassword: Yup.string().min(8, "Minimum 8 Character Required").required("New Password is required"),
    confirmPassword: Yup.string()
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("newPassword")], "Password Not Match"),
      })
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(
        resetPassword({ password: values.confirmPassword, userId: userId }, (response) => {
          enqueueSnackbar("Password Updated", {
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            variant: "success",
          });
          localStorage.removeItem("forgotEmail");
          navigate("/", { replace: true });
        })
      );
    },
  });

  const { errors, touched, resetForm } = formik;

  useEffect(() => {
    UserApis.getCurrentUser(forgotEmail).then((response) =>  {
     setUserId(response?.data?.id)
    })
  }, []);
  return (
    <Box>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ overflowY: "scroll", height: "100vh" }}>
            <Box sx={{ marginX: 10, paddingY: 5 }} className="formlayout">
              <Box className="logoDiv">
                <Logo LogoImg={LogoName} />
              </Box>
              <Divider className={classes.divider} />

              <Box>
                <Box sx={{ mt: 7, mb: 5 }} className="head_login_signup">
                  <Typography className="login_signup">Create New Password</Typography>
                </Box>

                <Box>
                  <FormikProvider value={formik}>
                    <Form ref={form} onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                      <Stack spacing={4}>
                        <TextField
                          value={forgotEmail}
                          disabled
                          fullWidth
                          label="Email"
                          type="text"
                        />
                        <TextField
                          value={formik.values.newPassword}
                          name="newPassword"
                          color="success"
                          onChange={formik.handleChange}
                          fullWidth
                          type={showNewPassword ? "text" : "password"}
                          label="New Password"
                          error={Boolean(touched.newPassword && errors.newPassword)}
                          helperText={touched.newPassword && errors.newPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={(event, value) => handleShowNewPassword(event, value)} edge="end">
                                  <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          value={formik.values.confirmPassword}
                          name="confirmPassword"
                          color="success"
                          onChange={formik.handleChange}
                          fullWidth
                          type={showConfirmPassword ? "text" : "password"}
                          label="Confirm Password"
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowConfirmPassword} edge="end">
                                  <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <CommonButton
                          variant={"contained"}
                          type={"submit"}
                          style={{
                            padding: 1.5,
                            bgcolor: "#009A4E",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#009A4E",
                            },
                          }}
                        >
                          Reset Password
                        </CommonButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6}>
          <AnimationImage />
        </Grid>
      </Grid>
    </Box>
  );
}
