import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";

import Pagination from "@mui/material/Pagination";

const useStyles = makeStyles((theme) => ({
  pageNumber: {
    display: "flex",
    paddingBottom: "50px",
    justifyContent: "center",
    maxWidth: "900px",
  },
  pageNumberSize: {
    size: "large",
    [theme.breakpoints.down("600")]: {
      size: "small"
    }
  }
}));

const Paging = ({ showPerPage, onPaginationChange, total }) => {
  const classes = useStyles();
  const [counter, setCounter] = useState(1);
  const [numberOfButtons, setNumberOfButtons] = useState(Math.ceil(total / showPerPage));

  useEffect(() => {
    const value = showPerPage * counter;
    onPaginationChange(value - showPerPage, value);
  }, [counter]);

  return (
    <Box className={classes.pageNumber}>
      <Pagination
        count={numberOfButtons}
        color="success"
        variant="outlined"
        shape="rounded"
        size={classes.pageNumberSize}
        onChange={(event, page_number) => setCounter(page_number)}
      />
    </Box>
  );
};

export default Paging;
