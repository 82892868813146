import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function ConfirmDialog(props) {
  return (
    <>
      <Dialog open={props.open} keepMounted onClose={props.close} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={props.close}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={props.delete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
