import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";

import { AppBar, Box, Toolbar, Divider, Tooltip } from "@mui/material";
import { makeStyles, styled } from "@material-ui/core/styles";

import Logo from "../../common/Logo";
import LogoImg from "../../../assets/logos/logoImg.svg";
import LogoName from "../../../assets/logos/Logo.svg";

import HomeIconSelect from "../../../assets/icons/home_selected.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import AnsQueIconSelect from "../../../assets/icons/ans_que_select.svg";
import AnsQueIcon from "../../../assets/icons/answer_que.svg";
import ReadQueIconSelect from "../../../assets/icons/ask_que_select.svg";
import ReadQueIcon from "../../../assets/icons/ask_que.svg";

import CommonButton from "../../common/Button";
import { IS_MOBILE } from "../../../App.config";

const useStyles = makeStyles((theme) => ({
  logoutButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  smallScreenLoginButton: {
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      fontSize: "x-small"
    },
    // [theme.breakpoints.up(900)]: {
    //   display: "none !important",
    // },
  },
  navSingleIcon: {
    [theme.breakpoints.down("xs")]: {
      margin: "10px !important",
    },
  },
  navIcons: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end !important",
      height: "40px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    height: "70px",
  },

  navOnlyLogo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
    },
  },

  navLogo: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },

  navDivider: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
      border: "2px solid #009A4E !important",
      width: "100vw",
      position: "fixed",
    },
  },

  borderBottomClass: {
    borderBottom: "6px solid #EFC7A4",
    borderRadius: "10px",
    width: "24px",
  },

  ImgSize: {
    minWidth: "31px",
    minHeight: "31px",
  },

  iconLink: {
    [theme.breakpoints.down("sm")]: {
      margin: "5px !important",
    },
  },
}));

const IconNavLink = styled(NavLink)(({ theme }) => ({
  margin: 20,
  textDecoration: "none",
  minHeight: "60px",
  minWidth: "40px",
}));

export default function DashboardNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const [currentSelectedIcon, setCurrentSelectedIcon] = useState();
  const { allQuestions } = useSelector((state) => state.questionReducer);

  const loginToken = localStorage.getItem("LoginToken");

  const LogoutMindTap = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };
  const LoginMindTap = () => {
    navigate("/", { replace: true });
  };

  const getActiveClass = (path) => {
    const sunStringArray = location.pathname.split("/")[2];
    return sunStringArray === path;
  };
  useEffect(() => {
    if (getActiveClass("profile")) {
      setCurrentSelectedIcon(0);
    } else if (getActiveClass("answer-question")) {
      setCurrentSelectedIcon(1);
    } else if (getActiveClass("create-question")) {
      setCurrentSelectedIcon(2);
    }
  }, [location]);

  return (
    <>
      <Box>
        <AppBar
          elevation={0}
          position="fixed"
          color="inherit"
          sx={{
            height: 90,
            textAlign: "center",
            justifyContent: "center",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Box className={classes.navLogo} sx={{ cursor: "pointer" }}>
              <Logo LogoImg={LogoName} />
            </Box>

            <Box className={classes.navOnlyLogo} sx={{ display: "none" }}>
              <Logo LogoImg={LogoImg} />
            </Box>
            <Box className={classes.navIcons}>
              {loginToken && (
                <IconNavLink
                  className={classes.iconLink}
                  to={"/dashboard/create-question"}
                  onClick={(match) => {
                    if (match) {
                      setCurrentSelectedIcon(2);
                    }
                  }}
                >
                  <Tooltip title="Create" arrow>
                    <img
                      src={currentSelectedIcon === 2 ? ReadQueIconSelect : ReadQueIcon}
                      alt=""
                      className={classes.ImgSize}
                    />
                  </Tooltip>

                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box className={currentSelectedIcon === 2 ? classes.borderBottomClass : ""}></Box>
                  </Box>
                </IconNavLink>
              )}

              <IconNavLink
                className={classes.iconLink}
                to={`/dashboard/answer-question/${allQuestions[0]?.id}`}
                onClick={(match) => {
                  if (match) {
                    setCurrentSelectedIcon(1);
                  }
                  return match;
                }}
              >
                <Tooltip title="Answer" arrow>
                  <img
                    src={currentSelectedIcon === 1 ? AnsQueIconSelect : AnsQueIcon}
                    alt=""
                    className={classes.ImgSize}
                  />
                </Tooltip>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Box className={currentSelectedIcon === 1 ? classes.borderBottomClass : ""}></Box>
                </Box>
              </IconNavLink>

              {loginToken && (
                <IconNavLink
                  className={classes.iconLink}
                  to={!IS_MOBILE ? "/dashboard/profile/update-profile" : "/dashboard/profile"}
                  onClick={(match) => {
                    if (match) {
                      setCurrentSelectedIcon(0);
                    }
                    return match;
                  }}
                >
                  <Tooltip title="Profile" arrow>
                    <img
                      src={currentSelectedIcon === 0 ? HomeIconSelect : HomeIcon}
                      alt=""
                      className={classes.ImgSize}
                    />
                  </Tooltip>

                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box className={currentSelectedIcon === 0 ? classes.borderBottomClass : ""}></Box>
                  </Box>
                </IconNavLink>
              )}
            </Box>
            {loginToken && <Box className={classes.logoutButton}>
              <CommonButton
                variant={"contained"}
                onClick={LogoutMindTap}
                style={{
                  backgroundColor: "#009A4E",
                  fontFamily: "Montserrat",
                  textTransform: "none",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#009A4E",
                  },
                  width: "152px",
                  height: "42px",
                  borderRadius: "5px",
                }}
              >
                Logout
              </CommonButton>
            </Box>}

            {!loginToken && <Box >
              <CommonButton
                className={classes.smallScreenLoginButton}
                variant={"contained"}
                onClick={LoginMindTap}
                style={{
                  backgroundColor: "#009A4E",
                  fontFamily: "Montserrat",
                  textTransform: "none",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#009A4E",
                  },
                  width: "152px",
                  height: "42px",
                  borderRadius: "5px",
                }}
              >
                Login
              </CommonButton>
            </Box>}
          </Toolbar>
        </AppBar>

        <Divider className={classes.navDivider} sx={{ zIndex: 10 }} />
      </Box>
    </>
  );
}
