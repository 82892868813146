import { SET_USER_DETAIL, SET_USER_TOPIC_PREFERENCES } from "../actions/actionType";

const initialState = {
  userData: {},
  userPreferencesData: {}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DETAIL:
      return {
        ...state,
        userData: action.userData,
      };

    case SET_USER_TOPIC_PREFERENCES:
      return {
        ...state,
        userPreferencesData: action.userPreferencesData,
      };  

    default:
      return state;
  }
}
