import { useEffect, useState, useRef } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
  FormLabel,
  FormHelperText,
  OutlinedInput,
  Input
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import { DeleteForever } from '@mui/icons-material';

import CommonButton from '../../../components/common/Button';
import Loader from '../../../components/common/Loader';
import { createQuestion, userUpdateQuestion, getSimilarQuestions, getQuestionCategory } from '../../../actions/questions-actions';
import { setTopics } from '../../../actions/topic-action';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import { UPLOAD_IMAGE_SIZE, UPLOAD_VIDEO_SIZE } from '../../../App.config';
import Info from '../../../components/common/Info';
import { CategorySubcategoryApis } from '../../../api/category-subcategory-api';

const useStyles = makeStyles((theme) => ({
  postQueButton: {
    [theme.breakpoints.down('900')]: {
      position: 'fixed !important',
      left: 40,
      bottom: 0,
      right: 40
    }
  },

  relateQue: {
    [theme.breakpoints.down('900')]: {
      marginTop: '0px !important',
      marginBottom: '60px !important'
    }
  },

  uploadInput: {
    backgroundColor: '#FFF',
    border: '1px solid #828282',
    borderRadius: '10px',
    padding: '8px'
  },

  buttonCss: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px'
    }
  },

  inputBoxCSS: {
    backgroundColor: '#FFF',
    padding: '8px',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.05)',
    '& > fieldset': {
      border: '1px solid transparent',
      outline: '1px solid transparent '
    }
  },

  labelCSS: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 900,
    color: '#000000 !important',
    paddingBottom: '5px'
  },
  mediaTypeAccept: {
    color: 'red',
    fontStyle: 'italic',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    marginTop: '3px'
  },
  autoCompleteCSS: {
    borderRadius: '10px',
    boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.05)',
    '& .MuiOutlinedInput-notchedOutline ': {
      border: '1px solid transparent',
      outline: '1px solid transparent '
    }
  },

  showList: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.05)',
    padding: '8px',
    marginBottom: '16px',
    cursor: 'pointer'
  },
  fileList: {
    marginTop: '16px',
    marginBottom: '16px',
    alignItems: 'center'
  },
  icon: {
    verticalAlign: 'middle',
    padding: '0px',
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(290)]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },

  element: {
    fontFamily: 'Montserrat',
    paddingLeft: '10px',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  coverImage: {
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'end'
    }
  },
  dropDownMenu: {
    display: 'flex',
    marginTop: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
}));

export default function CreateQuestion() {
  const userID = localStorage.getItem('refID');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  let mapValue = [];
  const { topics } = useSelector((state) => state.topicReducer);
  const { similarQuestions, questionCategory } = useSelector((state) => state.questionReducer);
  const [imageVideoMedia, setImageVideoMedia] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteCurrentMedia, setDeleteCurrentMedia] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const dragItem = useRef();
  const dragOverItem = useRef();

  const createQuestionSchema = Yup.object().shape({
    title: Yup.string()
      .trim('Blank Space Note Allowed')
      .strict(true)
      .required('Question is required'),
    // category: Yup.object().shape({
    //   category: Yup.string().required('Category is required'),
    //   categoryid: Yup.string().required('Category is required'),
    // }),
    // subcategory: Yup.object().shape({
    //   subcategory: Yup.string().required('Subcategory is required'),
    //   subcategoryid: Yup.string().required('Subcategory is required'),
    // }),
    description: Yup.string()
      .trim('Blank Space Note Allowed')
      .strict(true)
      .required('Description is required')
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      category: {},
      subcategory: {},
      description: ''
    },
    validationSchema: createQuestionSchema,
    onSubmit: (values) => {
      if (location?.state) {
        values.media = imageVideoMedia;
        dispatch(
          userUpdateQuestion(values, location?.state?.id, userID, () => {
            enqueueSnackbar('Questions Updated Successfully', {
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
              },
              variant: 'success'
            });
            formik.setFieldValue('title', '');
            formik.setFieldValue('category', '');
            formik.setFieldValue('subcategory', '');
            formik.setFieldValue('description', '');

            setImageVideoMedia([]);
            resetForm();
            delete location.state;
          })
        );
      } else {
        values.media = imageVideoMedia;
        dispatch(
          createQuestion(values, userID, () => {
            enqueueSnackbar('Questions Created Successfully', {
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
              },
              variant: 'success'
            });
            setImageVideoMedia([]);
            resetForm();
          })
        );
      }
    }
  });

  const selectMedia = () => {
    document.getElementById('selectedImage').click();
  };

  const openConfirmDelete = (index) => {
    setDeleteCurrentMedia(index);
    setDeleteDialog(true);
  };

  const closeConfirmDelete = () => {
    setDeleteDialog(false);
    setDeleteCurrentMedia('');
  };

  const removeItem = (index) => {
    const updateItems = imageVideoMedia.filter((element, index1) => {
      return index1 !== index;
    });
    setDeleteDialog(false);
    setImageVideoMedia(updateItems);
    setDeleteCurrentMedia('');
  };

  const dragStart = (event, position) => {
    dragItem.current = position;
  };

  const dragEnter = (event, position) => {
    dragOverItem.current = position;
  };

  const drop = (event) => {
    const copyListItems = [...imageVideoMedia];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setImageVideoMedia(copyListItems);
  };

  useEffect(() => {
    dispatch(setTopics());

    if (location?.state !== null) {
      formik.setFieldValue('title', location?.state?.title);
      formik.setFieldValue('description', location?.state?.description);
      formik.setFieldValue('category', { categoryid: location?.state?.categoryid, category: location?.state?.categoryname });
      setCategory({ category: location?.state?.categoryname, categoryid: location?.state?.categoryid })
      CategorySubcategoryApis.getSubCategoriesByCategoryId(location?.state?.categoryid).then((response)=>{
        setSubCategories(response.data);
      }).catch((e)=>{})
      setSubCategory({ subcategory: location?.state?.subcategoryname, subcategoryid: location?.state?.subcategoryid })
      formik.setFieldValue('subcategory', { subcategoryid: location?.state?.subcategoryid, subcategory: location?.state?.subcategoryname });
    }

    if (location?.state?.media.length > 0) {
      location?.state?.media?.map((mediaName) => {
        if(typeof mediaName.otherDetail == 'string') {
          mediaName.otherDetail = JSON.parse(mediaName.otherDetail)
        }
        mapValue.push(mediaName);
      })
      setImageVideoMedia(mapValue);
    }
  }, []);


  const { errors, touched, resetForm } = formik;
  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
          <Loader />
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ margin: 5 }}>
                <Box sx={{ marginTop: 5 }}>
                  <Box sx={{ marginTop: 2 }}>
                    <FormControl
                      error={Boolean(touched.title && errors.title)}
                      variant="standard"
                      fullWidth
                    >
                      <FormLabel className={classes.labelCSS}>Question</FormLabel>

                      <OutlinedInput
                        color="success"
                        name="title"
                        type="text"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        multiline
                        rows={5}
                        className={classes.inputBoxCSS}
                        onBlur={() => dispatch(getSimilarQuestions(formik.values.title, 5))}
                        inputProps={{
                          placeholder: 'type your question ...',
                          style: {
                            fontSize: '22px',
                            fontFamily: 'Montserrat',
                            fontWeight: 900,
                            marginTop: '11px',
                            marginLeft: '17px',
                            '& ::placeholder': {
                              color: '#E0E0E0'
                            }
                          }
                        }}
                      />

                      <FormHelperText>{touched.title && errors.title}</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
                {similarQuestions.length > 0 && <Info infoText={similarQuestions.map((q, i) => <p>{i+1}: {q.title}</p>)} />}

                <Box className={classes.dropDownMenu}>
                  <Autocomplete
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.category === value.category}
                    options={topics && topics?.map((option) => option)}
                    getOptionLabel={(option) => option.category ? option.category : ''}
                    sx={{ marginRight: 2, flexBasis: '40%' }}
                    value={category}
                    onChange={(event, value) => {
                      delete location?.state?.subcategory;
                      setCategory(value);
                      CategorySubcategoryApis.getSubCategoriesByCategoryId(value.categoryid).then((response)=>{
                        setSubCategories(response.data);
                      }).catch((e)=>{})
                      formik.setFieldValue('category', value);
                    }}
                    disableClearable
                    /* onInputChange={() => formik.setFieldValue('subcategory', '')} */
                    renderInput={(params) => (
                      <FormControl
                        fullWidth
                        variant="standard"
                        error={Boolean(
                          formik?.touched?.category && formik.errors?.category
                        )}
                      >
                        <FormLabel className={classes.labelCSS}>Category</FormLabel>

                        <TextField
                          {...params}
                          name="category"
                          color="success"
                          variant="outlined"
                          className={classes.autoCompleteCSS}
                          sx={{
                            backgroundColor: '#FFF'
                          }}
                          error={Boolean(
                            formik.touched.category && formik.errors.category
                          )}
                        />

                        <FormHelperText>
                          {formik.touched.category?.category && formik.errors.category?.category}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />

                  <Autocomplete
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.subcategory === value.subcategory}
                    options={subCategories && subCategories?.map((option) => option)}
                    getOptionLabel={(option) => option.subcategory ? option.subcategory : ''}
                    sx={{ flexBasis: '60%', marginTop: { xs: 2, sm: 0, lg: 0, xl: 0 } }}
                    value={subCategory}
                    onChange={(event, value) =>  { console.log("on change:", value);formik.setFieldValue('subcategory', value); setSubCategory(value)} }
                    onInputChange={(event) => {
                      if(event)
                        formik.setFieldValue('subcategory', location?.state?.subcategory)
                      }
                    }
                    disableClearable
                    renderInput={(params) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={Boolean(formik.touched?.subcategory && formik.errors?.subcategory)}
                      >
                        <FormLabel className={classes.labelCSS}>Subcategory</FormLabel>

                        <TextField
                          {...params}
                          name="subcategory"
                          color="success"
                          className={classes.autoCompleteCSS}
                          sx={{ backgroundColor: '#FFF' }}
                          variant="outlined"
                          error={Boolean(formik.touched?.subcategory && formik.errors?.subcategory)}
                        />

                        <FormHelperText>
                          {' '}
                          {formik.touched?.subcategory?.subcategory && formik.errors?.subcategory?.subcategory}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>

                <Box sx={{ marginTop: 5 }}>
                  <Box sx={{ marginTop: 2 }}>
                    <FormControl
                      error={Boolean(touched.description && errors.description)}
                      variant="standard"
                      fullWidth
                    >
                      <FormLabel className={classes.labelCSS}>Description</FormLabel>

                      <OutlinedInput
                        color="success"
                        name="description"
                        type="text"
                        multiline
                        sx={{
                          minHeight: '70px'
                        }}
                        className={classes.inputBoxCSS}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        inputProps={{
                          placeholder: 'Add Description',
                          style: {
                            textAlign: 'justify',
                            paddingRight: '4px',
                            fontSize: '16px',
                            fontFamily: 'Montserrat',
                            fontWeight: 900,
                            marginLeft: '7px',
                            '& ::placeholder': {
                              color: '#828282'
                            }
                          }
                        }}
                      />
                      <FormHelperText>{touched.description && errors.description}</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ marginTop: 5 }}>
                  <Box sx={{ paddingBottom: '5px' }}>
                    <Typography className={classes.labelCSS}>Upload Images and Videos</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                      <Box className={classes.mediaTypeAccept}>
                        <span style={{ fontFamily: 'Montserrat', fontWeight: 600, color: 'black' }}>
                          accept :{' '}
                        </span>
                        .jpg , .jpeg , .png , video
                      </Box>
                      <Box className={classes.mediaTypeAccept}>
                        <span style={{ fontFamily: 'Montserrat', fontWeight: 600, color: 'black' }}>
                          Image Upto :{' '}
                        </span>
                        5MB
                      </Box>
                      <Box className={classes.mediaTypeAccept}>
                        <span style={{ fontFamily: 'Montserrat', fontWeight: 600, color: 'black' }}>
                          Video Upto :{' '}
                        </span>
                        50MB
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.fileList}>
                    {imageVideoMedia?.length > 0 &&
                      imageVideoMedia?.map((element, index) => (
                        <Box
                          className={classes.showList}
                          key={index}
                          onDragStart={(event) => dragStart(event, index)}
                          onDragEnter={(event) => dragEnter(event, index)}
                          onDragEnd={drop}
                          draggable
                        >
                          <Typography className={classes.element}>{element?.otherDetail?.name || element?.name}</Typography>

                          {index === 0 && (
                            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                              <Typography className={classes.coverImage}>(Cover Image)</Typography>
                            </Box>
                          )}

                          <CommonButton
                            className={classes.icon}
                            variant={'text'}
                            color={'error'}
                            onClick={() => openConfirmDelete(index)}
                          >
                            <DeleteForever />
                          </CommonButton>
                        </Box>
                      ))}
                  </Box>
                  <label>
                    <Input
                      type="file"
                      name="file"
                      inputProps={{
                        accept: 'image/jpg, image/jpeg, image/png, image/gif, video/* ',
                        multiple: 'file'
                      }}
                      sx={{ display: 'none' }}
                      id="selectedImage"
                      onChange={(event) => {
                        if (
                          event.target.files[0].type.split('/')[0] === 'image' &&
                          event.target.files[0].size < UPLOAD_IMAGE_SIZE
                        ) {
                          setImageVideoMedia([...imageVideoMedia, ...event.target.files]);
                        } else if (
                          (event.target.files[0].type.split('/')[0] === 'video' ||
                            event.target.files[0].type.split('/')[0] === 'others') &&
                          event.target.files[0].size < UPLOAD_VIDEO_SIZE
                        ) {
                          setImageVideoMedia([...imageVideoMedia, ...event.target.files]);
                        } else {
                          enqueueSnackbar('Large File Size', {
                            anchorOrigin: {
                              horizontal: 'right',
                              vertical: 'top'
                            },
                            variant: 'error'
                          });
                        }
                      }}
                    />

                    <CommonButton
                      variant={'contained'}
                      style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '17px',
                        backgroundColor: '#009A4E',
                        boxShadow: ' 0px 0px 4px 1px rgba(0, 0, 0, 0.05)',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#009A4E'
                        },
                        height: '50px',
                        borderRadius: '10px',
                        marginBottom: { xs: '30px', sm: '0px', md: '0px', lg: '0px', xl: '0px' }
                      }}
                      onClick={selectMedia}
                    >
                      Add Files
                    </CommonButton>
                  </label>
                </Box>

                <Box sx={{ marginTop: 5 }} className={classes.postQueButton}>
                  <CommonButton
                    fullWidth={true}
                    variant={'contained'}
                    className={classes.buttonCss}
                    type={'submit'}
                    style={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '17px',
                      backgroundColor: '#009A4E',
                      boxShadow: ' 0px 0px 4px 1px rgba(0, 0, 0, 0.05)',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#009A4E'
                      },
                      height: '50px',
                      borderRadius: '10px'
                    }}
                  >
                    {location.state ? 'Update Your Question' : 'Post Your Question'}
                  </CommonButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <ConfirmDialog
        open={deleteDialog}
        close={() => closeConfirmDelete()}
        delete={() => removeItem(deleteCurrentMedia)}
      />
    </>
  );
}
