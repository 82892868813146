import { Outlet } from "react-router";
import { styled } from "@mui/material/styles";

import bodyBackground from "../../../assets/images/body_background.webp";

import NavBar from "./NavBar";

const MainStyle = styled("div")(({ theme }) => ({
  marginTop: 90,
  minHeight: "calc(100vh - 90px)",
  background: `URL(${bodyBackground})`,
  backgroundSize: "cover",
}));

export default function DashboardLayout(props) {
  return (
    <>
      <NavBar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </>
  );
}
