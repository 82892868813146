import { CommonApis } from "../api/commons-api";
import { SET_COUNTRIES } from "./actionType";
import { setLoaderValue } from "./master-actions";

export const setCountry = (countries) => ({
  type: SET_COUNTRIES,
  countries,
});

export const setCountries = () => (dispatch) => {
  dispatch(setLoaderValue(true));
  CommonApis.getAllCountries().then((result) => {
    dispatch(setCountry(result.data));
    dispatch(setLoaderValue(false));
  });
};
