import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Info = ({ infoText }) => {
    const classes = useStyles();
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className={`${classes.alert} ${classes.alertInfo}`}>
        <p>{infoText}</p>
        <button className={classes.close} onClick={handleClose}>&times;</button>
      </div>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  alertInfo: {
    color: '#fff',
    backgroundColor: theme.palette.info.light,
    borderColor: theme.palette.info.dark,
  },
  close: {
    float: 'right',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1,
    color: theme.palette.text.primary,
    textShadow: '0 1px 0 #fff',
    opacity: 0.5,
    cursor: 'pointer',
  },
}));

export default Info;
