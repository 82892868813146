const axios = require('axios');

export function request(url, method, payload, headers) {
  headers = headers == null ? { 'Access-Control-Allow-Origin': '*' } : { ...headers, 'Access-Control-Allow-Origin': '*' };
  headers['authorization'] = localStorage.getItem('LoginToken')
  return new Promise((resolve, reject) => {
    const allowedMethodTypes = ['get', 'post', 'put', 'delete', 'patch'];
    if (allowedMethodTypes.indexOf(method.toLowerCase()) < 0) {
      throw new Error(`
      Invalid method type please provide one of these methods... \n${allowedMethodTypes}`);
    } else {
      axios({
        method,
        url,
        data: payload,
        headers
      })
        .then((response) => {
          // response fetched successfully
          resolve(response);
        })
        .catch((error) => {
          // error from api
          reject(error);
        });
    }
  });
}
