import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import _ from 'underscore';
import moment from "moment";
import { useSnackbar } from "notistack";

import StarRateIcon from "@mui/icons-material/StarRate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import Paging from "../common/Paging";

import { deleteUserQuestion, deleteQuestion, userQuestions, userUpdateQuestion, setQuestionsMediaByUserId } from "../../actions/questions-actions";
import ConfirmDialog from "../common/ConfirmDialog";
import { IMAGE_MAIN_URL, CLOUD_FRONT_IMAGE_URL } from '../../App.config';
import { MediaApis } from "../../api/media-api";

const useStyles = makeStyles((theme) => ({
  disabledBox: {
    pointerEvents: "none",
    opacity: 0.6,
    background: "#FFF",
    height: "auto",
    padding: "20px !important",
    borderRadius: "15px 15px 15px 15px ",
    borderRight: "7px solid #b0b0b0",
    borderLeft: "7px solid #b0b0b0",
    maxWidth: "700px",
    marginBottom: "20px !important",
  },
  orangeBox: {
    background: "#FFF",
    height: "auto",
    padding: "20px !important",
    borderRadius: "15px 15px 15px 15px ",
    borderRight: "7px solid #EFC7A4",
    borderLeft: "7px solid #EFC7A4",
    maxWidth: "700px",
    marginBottom: "20px !important",
  },
  questionTitle: {
    fontWeight: 600,
    fontSize: "22px",
    textAlign: "justify",
    overflowWrap: "break-word",
    textAlign: "justify",
    wordSpacing: "6px",
    lineHeight: "25px",

  },
  boxPadding: {
    padding: "80px 80px 20px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },

  descriptionEllipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    textAlign: "justify",

  },

  categoryAndTime: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    marginTop: "20px",

  },

  categorySubcategory: {
    color: "#2F80ED",
    fontWeight: 600,
  },

  answerTime: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
  },

  mediaAndDescription: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  mediaWidth: {
    borderRadius: "20px",
    height: "163px",
    width: "282px",
    [theme.breakpoints.down("361")]: {
      width: "100%",
    },
  },
}));

export default function MyQuestion() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const userID = localStorage.getItem("refID");
  const [mediaURLs, setMediaURLs] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState("");
  const { userQuestion, questionsMedia } = useSelector((state) => state.questionReducer);

  /*  media pagination in fronted side ///////////////////////////////////*/
  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  /*  media pagination in fronted side //////////////////////////////////*/

  function timeStampToTime(timeStamp) {
    const time = moment(timeStamp).format("LLL");
    return time;
  }

  const setQuestionToUpdateQuestion = (question) => {
    let mediaUrls = _.where(questionsMedia, { QuestionId: question.id });
    question.media = mediaUrls
    navigate("/dashboard/create-question", { state: question });
  };

  const openConfirmDelete = (questionId) => {
    setQuestionToDelete(questionId);
    setDeleteDialog(true);
  };
  const closeConfirmDelete = () => {
    setDeleteDialog(false);
    setQuestionToDelete("");
  };
  const deleteMyQuestion = (questionId) => {
    dispatch(deleteQuestion({ isActive: false }, questionId, () => {
      dispatch(userQuestions(userID));
      enqueueSnackbar("Questions Deleted ", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "success",
      });
    }))
    setDeleteDialog(false);
    setQuestionToDelete("");
  }

  useEffect(() => {
    dispatch(userQuestions(userID));
    dispatch(setQuestionsMediaByUserId());
  }, []);

  let keyArray = [];

  useEffect(() => {
    userQuestion &&
      userQuestion?.map((mediaKey) => {
        if (mediaKey?.data?.media && mediaKey?.data?.media.length > 0) {
          keyArray.push(mediaKey?.data?.media[0]?.Key);
        }
        /* QuestionApis.questionUserCount(mediaKey?.ref?.value?.id); */
      });

    MediaApis.getMediaURL({ keys: keyArray }).then((response) => {
      let mediaObjArray = [];
      let counter = 0;
      userQuestion.map((mediaKey) => {
        if (mediaKey?.data?.media?.length > 0) {
          mediaObjArray.push({
            mediaUrl: response.data[counter],
          });
          counter++;
        } else {
          mediaObjArray.push({
            mediaUrl: "",
          });
        }
      });
      setMediaURLs(mediaObjArray);
    });
  }, [userQuestion]);

  let replaceWithCloudFrontURL = (imageURL) => {
    return imageURL.replace(IMAGE_MAIN_URL, CLOUD_FRONT_IMAGE_URL)
  }

  let MediaComponent = (props) => {
    let questionId = props.question.id;
    let mediaUrls = _.where(questionsMedia, { QuestionId: questionId });
    return (
      <>
        {mediaUrls && mediaUrls.length > 0 && (
            (mediaUrls.map((currentMediaUrl)=>{
              return (
                <Box sx={{ display: "flex", mt: 3 }} className={classes.mediaAndDescription}>
                {currentMediaUrl?.URL?.split("/")[3] === "image" ? (
                  <>
                    <Box>
                      <img src={replaceWithCloudFrontURL(currentMediaUrl?.URL)} className={classes.mediaWidth} />
                    </Box>
                  </>
                ) : (
                  (currentMediaUrl?.URL?.split("/")[3] === "others" || "video") && (
                    <>
                      <Box>
                        <video
                          controls="controls"
                          src={replaceWithCloudFrontURL(currentMediaUrl?.URL)}
                          className={classes.mediaWidth}
                        ></video>
                      </Box>
                    </>
                  )
                )}
              </Box>
              )
            }))          
        )
        }
      </>
    )
  }

  return (
    <>
      <Box className={classes.boxPadding}>
        {userQuestion &&
          userQuestion?.slice(pagination.start, pagination.end)?.map((question, index) => {
            return (
              <Box
                className={!question?.isActive ? classes.disabledBox : classes.orangeBox}
                key={index}
                sx={{
                  " & :hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {!question?.isActive && <Box sx={{ fontWeight: 600, fontSize: "14px", color: "red", textAlign: "end" }}>Question Deleted </Box>}
                {question?.isActive && <Box sx={{ textAlign: "end", color: "red" }}>
                  <DeleteOutlineIcon onClick={() => openConfirmDelete(question.id)} />
                </Box>}
                <Box onClick={(event) => setQuestionToUpdateQuestion(question)}>
                  <Box className={classes.questionTitle}>{question?.title}</Box>
                  <MediaComponent question={question}></MediaComponent>
                  <Box sx={{ mt: 1 }} className={classes.descriptionEllipsis}>
                    {question?.description}
                  </Box>
                  <Box sx={{ mt: 1 }} className={classes.categoryAndTime}>
                    <Box className={classes.categorySubcategory} sx={{ verticalAlign: "middle" }}>
                      {question?.categoryname} / {question?.subcategoryname}
                    </Box>
                    {question?.avgRating && (
                      <Box>
                        <Box
                          sx={{
                            color: "#EB5757",
                          }}
                        >
                          <StarRateIcon
                            sx={{
                              fontSize: "14px",
                              verticalAlign: "middle",
                            }}
                          />
                          <span
                            style={{
                              marginLeft: "3px",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              fontWeight: 600,
                              verticalAlign: "middle",
                            }}
                          >
                            {question?.avgRating.toFixed(1)}
                          </span>
                        </Box>
                      </Box>
                    )}

                    {question?.responseCount > 0 && (
                      <Box>
                        <Tooltip title="Response" arrow>
                          <Box
                            sx={{
                              color: "#009A4E",
                            }}
                          >
                            <CheckCircleIcon
                              sx={{
                                fontSize: "14px",
                                verticalAlign: "middle",
                              }}
                            ></CheckCircleIcon>
                            <span
                              style={{
                                marginLeft: "3px",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              {question?.responseCount}
                            </span>
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                    <Box className={classes.answerTi10me}>{timeStampToTime(question?.createdAt)}</Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        <ConfirmDialog
          open={deleteDialog}
          close={() => closeConfirmDelete()}
          delete={() => deleteMyQuestion(questionToDelete)}
        />
        {userQuestion && userQuestion.length === 0 && (
          <Box sx={{ display: "grid", placeItems: "center", minHeight: "70vh" }}>
            <Box className={classes.orangeBox} key={null}>
              <Box className={classes.question}>Questions Not Created </Box>
            </Box>
          </Box>
        )}
        {userQuestion && userQuestion.length > 10 && (
          <Paging showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={userQuestion?.length} />
        )}
      </Box>

    </>
  );
}
