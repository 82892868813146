import { CommonApis } from "../api/commons-api";
import { SET_RACES } from "./actionType";
import { setLoaderValue } from "./master-actions";

export const setRace = (races) => ({
  type: SET_RACES,
  races,
});

export const setRaces = () => (dispatch) => {
  dispatch(setLoaderValue(true));
  CommonApis.getAllRaces().then((result) => {
    dispatch(setRace(result.data));
    dispatch(setLoaderValue(false));
  });
};
