import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  TextField,
  Grid,
  Stack,
  Link,
  Typography,
  Divider,
  InputAdornment,
  IconButton
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import Logo from '../../../components/common/Logo';
import LogoName from '../../../assets/logos/Logo.svg';

import SocialAuth from '../../../components/common/SocialAuth';
import CommonButton from '../../../components/common/Button';

import { isEmailExist, signup } from '../../../actions/authentication-actions';
import AnimationImage from '../../../components/common/AnimationImage';

const useStyles = makeStyles((theme) => ({
  bodyScroll: {
    [theme.breakpoints.down('900')]: {
      overflowY: 'auto'
    }
  },
  divider: {
    display: 'none',
    [theme.breakpoints.down('900')]: {
      display: 'block',
      border: '1px solid #009A4E',
      marginTop: '10px'
    }
  }
}));

export default function Signup() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().min(8, 'Minimum 8 Character Required').required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(
        isEmailExist(values.email, (emailExistResponse) => {
          emailExistResponse = emailExistResponse[0].data;
          if (emailExistResponse.length === 0) {
            dispatch(
              signup(values, () => {
                enqueueSnackbar('Successfully Signup', {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'success'
                });
                localStorage.setItem('emailID', values.email);
                navigate('/', { replace: true });
              })
            );
            resetForm();
          } else {
            enqueueSnackbar('Email Already Exists', {
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
              },
              variant: 'error'
            });
          }
        })
      );
    }
  });

  const { errors, touched, resetForm } = formik;

  return (
    <Box>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ overflowY: 'scroll', height: '100vh' }} className={classes.bodyScroll}>
            <Box sx={{ marginX: 10, paddingTop: 5 }} className="formlayout">
              <Box className="logoDiv">
                <Logo LogoImg={LogoName} />
                <Box className="links">
                  <Link
                    className="link_login_signup"
                    sx={{ marginLeft: '30px' }}
                    to="/"
                    component={RouterLink}
                  >
                    Login
                  </Link>
                </Box>
              </Box>
            </Box>

            <Divider className={classes.divider} />

            <Box sx={{ marginX: 10, paddingBottom: 5 }} className="formlayout">
              <Box>
                <Box sx={{ mt: 7, mb: 5 }} className="head_login_signup">
                  <Typography className="login_signup">Sign up.</Typography>

                  <Link className="side_login_signup" to="/" component={RouterLink}>
                    {' '}
                    Log in
                  </Link>
                </Box>

                <Box>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                      <Stack spacing={4}>
                        <TextField
                          color="success"
                          fullWidth
                          type="text"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          name="name"
                          label="Your Name"
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />

                        <TextField
                          color="success"
                          fullWidth
                          type="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          name="email"
                          label="Your Email"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />

                        <TextField
                          color="success"
                          fullWidth
                          type={showPassword ? 'text' : 'password'}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          name="password"
                          label="Your Password"
                          placeholder="at least 8 character"
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        <CommonButton
                          variant={'contained'}
                          type={'submit'}
                          style={{
                            padding: 1.5,
                            bgcolor: '#009A4E',
                            marginBottom: '20px !important',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: '#009A4E'
                            }
                          }}
                        >
                          Create account
                        </CommonButton>
                        <SocialAuth />
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Box>
                <Box className="termAndPrivacyLink">
                  <Link href="#" target="_blank" className="termsLink" to="/terms-condition" component={RouterLink}>
                      Terms & Condition
                  </Link>
                  <Link href="#" target="_blank" className="termsLinkPrivacy" to="/privacy-policy" component={RouterLink}>
                      Privacy Policy
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6}>
          <AnimationImage />
        </Grid>
      </Grid>
    </Box>
  );
}
