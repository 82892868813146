export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_USER_TOPIC_PREFERENCES = "SET_USER_TOPIC_PREFERENCES";

export const SET_TOPICS = "SET_TOPICS";
export const SET_SUBCATEGORIES = "SET_SUBCATEGORIES";

export const SET_RACES = "SET_RACES";
export const QUESTIONS_MEDIA = 'QUESTIONS_MEDIA';
export const SET_COUNTRIES = "SET_COUNTRIES";

export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";
export const SET_ALL_QUESTIONS = "SET_ALL_QUESTIONS";
export const REMOVE_QUESTION_FROM_ALL_QUESTIONS = "REMOVE_QUESTION_FROM_ALL_QUESTIONS";
export const SET_RELATED_QUESTIONS = "SET_RELATED_QUESTIONS";
export const SET_CATEGORY_WISE_DATA = "SET_CATEGORY_WISE_DATA";
export const SELECT_NEXT_QUESTION = "SELECT_NEXT_QUESTION";
export const SET_CURRENT_USER_QUESTION = "SET_CURRENT_USER_QUESTION";
export const SET_CURRENT_USER_ANSWERS = "SET_CURRENT_USER_ANSWERS";
export const SET_SEARCHED_QUESTIONS = "SET_SEARCHED_QUESTIONS";
export const SET_QUESTION_COMMENTS = "SET_QUESTION_COMMENTS";
export const SET_SIMILAR_QUESTIONS = "SET_SIMILAR_QUESTIONS";
export const SET_QUESTION_CATEGORY = "SET_QUESTION_CATEGORY";

export const SET_LOADER = "SET_LOADER";
