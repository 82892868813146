import { Outlet, useNavigate } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Typography, Divider } from '@mui/material';
import { makeStyles, styled } from '@material-ui/core/styles';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import CommonButton from '../../../components/common/Button';

import { IS_MOBILE } from '../../../App.config';

const MobileHomeMenuLink = styled(NavLink)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#000000 !important',
  fontSize: '16px',
  lineHeight: '17px',
  textDecoration: 'none !important',
  padding: '20px 20px',
  backgroundColor: '#FFF',
  display: 'block'
}));

const HomeMenuLink = styled(NavLink)(() => ({
  fontStyle: 'normal',
  fontWeight: 600,
  color: '#000000 !important',
  fontSize: '14px',
  lineHeight: '20px',
  textDecoration: 'none !important',
  height: '20px',
  backgroundColor: '#FFF',
  marginTop: '10px !important',
  marginBottom: '10px !important',
  padding: '15px 10px 15px 50px',
  display: 'block'
}));

const useStyles = makeStyles((theme) => ({
  activeLink: {
    backgroundColor: '#009A4E !important',
    color: '#FFF !important'
  },

  HomeWebMenu: {
    [theme.breakpoints.down('960')]: {
      display: 'none !important'
    }
  },

  HomeMobileMenu: {
    display: 'none',
    [theme.breakpoints.down('960')]: {
      display: 'block !important'
    }
  }
}));

export default function Profile() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const getActiveClass = (path) => {
    const sunStringArray = location.pathname.split('/');
    return sunStringArray[sunStringArray.length - 1] === path ? classes.activeLink : '';
  };

  const LogoutMindTap = () => {
    localStorage.clear();
    navigate('/', { replace: true });
  };

  return (
    <>
      {!IS_MOBILE && (
        <>
          <Box
            sx={{
              backgroundColor: '#F8F8F8',
              height: '100vh',
              width: 350,
              borderRight: '7px solid #009A4E',
              position: 'fixed'
            }}
          >
            <HomeMenuLink to="update-profile" className={getActiveClass('update-profile')}>
              Update Profile
            </HomeMenuLink>

            <HomeMenuLink
              to="question-preferences"
              className={getActiveClass('question-preferences')}
            >
              Question Preferences
            </HomeMenuLink>

            <HomeMenuLink to="my-question" className={getActiveClass('my-question')}>
              My Question
            </HomeMenuLink>

            <HomeMenuLink to="my-answers" className={getActiveClass('my-answers')}>
              My Answers
            </HomeMenuLink>
          </Box>
          <Box sx={{ marginLeft: '350px' }}>
            <Outlet />
          </Box>
        </>
      )}

      {IS_MOBILE && (
        <>
          {getActiveClass('profile') && (
            <Box className={classes.HomeMobileMenu}>
              <Typography
                sx={{
                  color: '#000000',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontFamily: 'Montserrat',
                  padding: '20px 10px'
                }}
              >
                ACCOUNT
              </Typography>
              <Box>
                <MobileHomeMenuLink to="update-profile">
                  Update Profile
                  <span style={{ float: 'right' }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 'small' }} />
                  </span>
                </MobileHomeMenuLink>

                <Divider />

                <MobileHomeMenuLink to="question-preferences">
                  Question Preferences
                  <span style={{ float: 'right' }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 'small' }} />
                  </span>
                </MobileHomeMenuLink>

                <Divider />

                <MobileHomeMenuLink to="my-question">
                  My Question
                  <span style={{ float: 'right' }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 'small' }} />
                  </span>
                </MobileHomeMenuLink>

                <Divider />

                <MobileHomeMenuLink to="my-answers">
                  My Answers
                  <span style={{ float: 'right' }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 'small' }} />
                  </span>
                </MobileHomeMenuLink>
              </Box>
            </Box>
          )}

          {location.pathname === '/dashboard/profile' && (
            <Box sx={{ margin: 2 }}>
              <CommonButton
                variant={'contained'}
                onClick={LogoutMindTap}
                fullWidth
                style={{
                  backgroundColor: '#009A4E',
                  fontFamily: 'Montserrat',
                  textTransform: 'none',
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#009A4E'
                  },
                  height: '42px',
                  borderRadius: '5px'
                }}
              >
                Logout
              </CommonButton>
            </Box>
          )}

          {!getActiveClass('profile') && <Outlet />}
        </>
      )}
    </>
  );
}
