import { SET_LOADER } from "../actions/actionType";

const initialState = {
  loaderState: 0,
};

export default function masterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        loaderState: action.loaderState ? state.loaderState + 1 : state.loaderState - 1,
      };

    default:
      return state;
  }
}
