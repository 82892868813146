import { SET_TOPICS, SET_SUBCATEGORIES } from "../actions/actionType";

const initialState = {
  topics: [],
  subCategories: []
};

export default function topicReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOPICS:
      return {
        ...state,
        topics: action.topics,
      };

    case SET_SUBCATEGORIES:
      return {
        ...state,
        subCategories: action.subCategories,
      };

    default:
      return state;
  }
}
