import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Box,
  Avatar,
  TextField,
  MenuItem,
  Button,
  FormControl,
  OutlinedInput,
  FormLabel,
  FormHelperText,
  Autocomplete
} from '@mui/material';
import { makeStyles, styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import CommonButton from '../common/Button';
// import { getLoginUserData, updateUserData } from '../../api';
import { setLoaderValue } from '../../actions/master-actions';
import { getUserData, updateUserData } from '../../actions/user-actions';
import { setRaces } from "../../actions/race-action";
import { setCountries } from '../../actions/country-action';
import { useLocation } from 'react-router-dom';
import _ from 'underscore';
import { IMAGE_MAIN_URL, CLOUD_FRONT_IMAGE_URL } from '../../App.config';
import { MediaApis } from '../../api/media-api';

const useStyles = makeStyles((theme) => ({
  profileButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100% !important'
    }
  },

  bodyPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: '20px !important'
    }
  },

  profileDetail: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '12px !important'
    }
  },

  profileName: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '21px',
    color: '#333',
    fontFamily: 'Montserrat'
  },

  changeProfileImage: {
    paddingLeft: '0px',
    fontWeight: 700,
    fontSize: '14px ',
    color: '#2F80ED',
    textTransform: 'none',
    fontFamily: 'Montserrat'
  },

  formLabel: {
    color: '#000 !important',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    paddingBottom: '5px'
  },

  inputBoxCSS: {
    backgroundColor: '#FFF',
    padding: '3px',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.05)',
    '& > fieldset': {
      border: '1px solid transparent',
      outline: '1px solid transparent '
    }
  },
  autoCompleteCSS: {
    backgroundColor: '#FFF',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.05)',
    '& .MuiOutlinedInput-notchedOutline ': {
      border: '1px solid transparent',
      outline: '1px solid transparent '
    }
  }
}));

const Lable = styled('lable')(({ theme }) => ({
  height: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  marginBottom: 10
}));

export default function UpdateProfile() {
  const location = useLocation();
  const Gender = ['Male', 'Female'];

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const loggedInUser = useSelector((state) => state.userReducer.userData);
  const { races } = useSelector((state) => state.raceReducer);
  const { countries } = useSelector((state) => state.countryReducer);
  const [newUploadedImageBase64, setNewUploadedImageBase64] = useState('');
  const [race, setRace] = useState({name: '', id: ''});
  const [country, setCountry] = useState({ name: '', id: ''});
  const [profileImage, setProfileImage] = useState([]);

  const UserRefID = localStorage.getItem('refID');
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone: Yup.string('Only Number Accepted')
      .max(10, 'Must Be 10 Digit')
      .min(10, 'Must Be 10 Digit')
      .required('Phone is required'),
    gender: Yup.string().required('Gender is required'),
    age: Yup.string().required('Age is required'),
    country: Yup.object().shape({
      id: Yup.string().required('Country is required'),
      name: Yup.string().required('Country is required'),
    }),
    race: Yup.object().shape({
      id: Yup.string().required('Race is required'),
      name: Yup.string().required('Race is required'),
    }),
    reputation: Yup.string().required('Reputation is required')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: `${loggedInUser?.data?.firstName}`,
      email: `${loggedInUser?.data?.email}`,
      phone: `${loggedInUser?.data?.phone}`,
      gender: loggedInUser?.data?.gender ? `${loggedInUser?.data?.gender}` : '',
      age: loggedInUser?.data?.age ? `${loggedInUser?.data?.age}` : '',
      race: { name: loggedInUser?.data?.race, id: loggedInUser?.data?.RaceId },
      country: { name: loggedInUser?.data?.country, id: loggedInUser?.data?.CountryId },
      reputation: loggedInUser?.data?.reputation ? `${loggedInUser?.data?.reputation}` : ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      values.profileImage = profileImage;
      dispatch(
        updateUserData(UserRefID, values, (result) => {
          enqueueSnackbar('Profile Update Successfully', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'success'
          });
        })
      );
    }
  });

  useEffect(() => {
    dispatch(getUserData(currentUserEmail));
    dispatch(setRaces());
    dispatch(setCountries());
  }, []);

  const keyArray = [];
  useEffect(() => {
    if (!typeof loggedInUser?.data?.profileImage === 'string') {
      loggedInUser?.data?.profileImage.map((profileImageKey) => {
        if (profileImageKey?.Key) {
          keyArray.push(profileImageKey?.Key);
        }
      });
      MediaApis.getMediaURL({ keys: keyArray }).then((response) => {
        setProfileImage(response.data[0]);
      });
    } else {
      let tempImage = loggedInUser?.data?.profileImage;

      setProfileImage(tempImage ? tempImage.replace(IMAGE_MAIN_URL, CLOUD_FRONT_IMAGE_URL) : '');
    }
    if(loggedInUser?.data?.race) {
      setRace({ name: `${loggedInUser?.data?.race}`, id: `${loggedInUser?.data?.RaceId}`})
    }
    if(loggedInUser?.data?.country) {
      setCountry({ name: `${loggedInUser?.data?.country}`, id: `${loggedInUser?.data?.CountryId}`})
    }
  }, [loggedInUser]);

  const previewAndUploadImage = (event) => {
    setProfileImage(event.target.files);

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = () => {
        setNewUploadedImageBase64(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const { errors, touched } = formik;
  return (
    <>
      <Box sx={{ padding: 10, maxWidth: 700 }} className={classes.bodyPadding}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
            <Grid container columnSpacing={3} rowGap={5}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Avatar
                      src={newUploadedImageBase64 || profileImage}
                      sx={{ width: '90px', height: '90px', borderRadius: '16px' }}
                    />
                  </Box>

                  <Box sx={{ pl: 7, pt: 2 }} className={classes.profileDetail}>
                    <Box className={classes.profileName}>{loggedInUser?.data?.firstName}</Box>

                    <Button variant="text" component="label" className={classes.changeProfileImage}>
                      Change Profile Photo
                      <input
                        name="profileImage"
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        hidden
                        onChange={(event) => previewAndUploadImage(event)}
                      />
                    </Button>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={12} sm={12} xs={12}>
                <FormControl error={Boolean(touched.firstName && errors.firstName)} variant="standard" fullWidth>
                  <FormLabel className={classes.formLabel}>Name</FormLabel>

                  <OutlinedInput
                    color="success"
                    autoComplete="off"
                    name="firstName"
                    className={classes.inputBoxCSS}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    type="text"
                  />

                  <FormHelperText>{touched.firstName && errors.firstName}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item lg={6} sm={12} xs={12}>
                <FormControl error={Boolean(touched.email && errors.email)} fullWidth variant="standard">
                  <FormLabel className={classes.formLabel}>Email</FormLabel>

                  <OutlinedInput
                    name="email"
                    className={classes.inputBoxCSS}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type="email"
                    disabled
                  />

                  <FormHelperText>{touched.email && errors.email}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item lg={6} sm={12} xs={12}>
                <FormControl fullWidth variant="standard" error={Boolean(touched.phone && errors.phone)}>
                  <FormLabel className={classes.formLabel}>Phone</FormLabel>

                  <OutlinedInput
                    name="phone"
                    autoComplete="off"
                    type="number"
                    color="success"
                    className={classes.inputBoxCSS}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />

                  <FormHelperText>{touched.phone && errors.phone}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item lg={4} sm={12} xs={12}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(formik.touched.gender && formik.errors.gender)}
                >
                  <FormLabel className={classes.formLabel}>Gender</FormLabel>

                  <TextField
                    name="gender"
                    className={classes.autoCompleteCSS}
                    color="success"
                    select
                    value={formik.values.gender}
                    onChange={(event) => formik.setFieldValue('gender', event.target.value)}
                  >
                    {Gender.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormHelperText>{formik.touched.gender && formik.errors.gender}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item lg={4} sm={12} xs={12}>
                <FormControl fullWidth variant="standard" error={Boolean(touched.age && errors.age)}>
                  <FormLabel className={classes.formLabel}>Age</FormLabel>

                  <TextField
                    name="age"
                    autoComplete="off"
                    type="number"
                    color="success"
                    className={classes.autoCompleteCSS}
                    value={formik.values.age}
                    onChange={formik.handleChange}
                  />

                  <FormHelperText>{touched.age && errors.age}</FormHelperText>
                </FormControl>
              </Grid>
{/* 
              <Grid item lg={4} sm={12} xs={12}>
                <FormControl fullWidth error={Boolean(touched.region && errors.region)} variant="standard">
                  <FormLabel className={classes.formLabel}>Region</FormLabel>

                  <OutlinedInput
                    className={classes.inputBoxCSS}
                    name="region"
                    color="success"
                    type="text"
                    autoComplete="off"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                  />

                  <FormHelperText>{touched.region && errors.region}</FormHelperText>
                </FormControl>
              </Grid> */}

              <Grid item lg={4} sm={12} xs={12}>
                {/* <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(formik.touched.country && formik.errors.country)}
                >
                  <FormLabel className={classes.formLabel}>Country</FormLabel>

                  <TextField
                    name="country"
                    color="success"
                    select
                    value={formik.values.country}
                    className={classes.autoCompleteCSS}
                    onChange={(event) => formik.setFieldValue('country', event.target.value)}
                    fullWidth
                  >
                    {Country.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormHelperText>{formik.touched.country && formik.errors.country}</FormHelperText>
                </FormControl> */}
                <Autocomplete
                      fullWidth
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      options={countries && countries?.map((option) => option)}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      sx={{ marginRight: 2, flexBasis: '40%' }}
                      value={country}
                      onChange={(event, value) => { if(value) {
                        formik.setFieldValue('country', value); 
                        setCountry(value)
                      } else {
                        formik.setFieldValue('country', {name: '', id: ''}); 
                        setCountry({name: '', id: ''})
                      } }}
                      renderInput={(params) => (
                        <FormControl
                          fullWidth
                          variant="standard"
                          error={Boolean(
                            formik?.touched?.country && formik.errors?.country
                          )}
                        >
                          <FormLabel className={classes.formLabel}>Country</FormLabel>

                          <TextField
                            {...params}
                            name="country"
                            color="success"
                            variant="outlined"
                            className={classes.autoCompleteCSS}
                            sx={{
                              backgroundColor: '#FFF'
                            }}
                            error={Boolean(
                              formik.touched.country && formik.errors.country
                            )}
                          />

                          <FormHelperText>
                            {formik.touched.country?.name && formik.errors.country?.name}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />

              </Grid>
              <Grid item lg={4} sm={12} xs={12}>
                <Autocomplete
                      fullWidth
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      options={races && races?.map((option) => option)}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      sx={{ marginRight: 2, flexBasis: '40%' }}
                      value={race}
                      onChange={(event, value) =>  { if(value) {
                        formik.setFieldValue('race', value); 
                        setRace(value)
                      } else {
                        formik.setFieldValue('race', {name: '', id: ''}); 
                        setRace({name: '', id: ''})
                      } }}
                     /*  onInputChange={() =>
                        formik.setFieldValue('race', location?.state?.data?.race)
                      } */
                      renderInput={(params) => (
                        <FormControl
                          fullWidth
                          variant="standard"
                          error={Boolean(
                            formik?.touched?.race && formik.errors?.race
                          )}
                        >
                          <FormLabel className={classes.formLabel}>Race</FormLabel>

                          <TextField
                            {...params}
                            name="race"
                            color="success"
                            variant="outlined"
                            className={classes.autoCompleteCSS}
                            sx={{
                              backgroundColor: '#FFF'
                            }}
                            error={Boolean(
                              formik.touched.race && formik.errors.race
                            )}
                          />

                          <FormHelperText>
                            {formik.touched.race?.name && formik.errors.race?.name}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />

               {/*  <FormControl error={Boolean(touched.race && errors.race)} fullWidth variant="standard">
                  <FormLabel className={classes.formLabel}>Race</FormLabel>

                  <OutlinedInput
                    className={classes.inputBoxCSS}
                    color="success"
                    autoComplete="off"
                    name="race"
                    type="text"
                    value={formik.values.race}
                    onChange={formik.handleChange}
                  />

                  <FormHelperText>{touched.race && errors.race}</FormHelperText>
                </FormControl> */}
              </Grid>

              <Grid item lg={4} sm={12} xs={12}>
                <FormControl error={Boolean(touched.reputation && errors.reputation)} fullWidth variant="standard">
                  <FormLabel className={classes.formLabel}>Reputation</FormLabel>

                  <OutlinedInput
                    className={classes.inputBoxCSS}
                    autoComplete="off"
                    name="reputation"
                    color="success"
                    type="text"
                    value={formik.values.reputation}
                    onChange={formik.handleChange}
                  />

                  <FormHelperText>{touched.reputation && errors.reputation}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <CommonButton
                  className={classes.profileButton}
                  variant={'contained'}
                  type={'submit'}
                  style={{
                    width: '270px',
                    height: '48px',
                    bgcolor: '#009A4E',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#009A4E'
                    }
                  }}
                >
                  Submit
                </CommonButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
}
