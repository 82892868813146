import * as React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

export default function ComingSoon(props) {
  return (
    <Box sx={{ padding: 10 }}>
      <Card
        sx={{ width: 500, textAlign: "center", padding: 10, margin: "0 auto", boxShadow: "5px 5px 5px 5px #009A4E" }}
      >
        <CardContent>
          <Typography
            sx={{ fontStyle: "italic", fontWeight: 900, fontSize: "30px", lineHeight: "21px", color: "#333333" }}
          >
            {props.title}
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 5 }}>
            Coming Soon
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
