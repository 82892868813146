export const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

/* export const FAUNADB_CLIENT_SECRET = process.env.REACT_APP_DB_KEY;

export const FAUNADB_GUEST_SECRET = process.env.REACT_APP_DB_GUEST_KEY; */

// export const SERVER_URL = 'https://api.mindtap.app';
// export const SERVER_URL = 'http://localhost:3000';
export const SERVER_URL = 'https://dev-api.mindtap.app';

export const UPLOAD_IMAGE_SIZE = process.env.REACT_APP_IMAGE_SIZE;
export const UPLOAD_VIDEO_SIZE = process.env.REACT_APP_VIDEO_SIZE;
export const IMAGE_MAIN_URL = "https://mindtap-files.s3.amazonaws.com";
export const CLOUD_FRONT_IMAGE_URL = "https://d1cjagj61417ru.cloudfront.net";