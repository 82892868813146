import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { GoogleLogin, useGoogleLogin } from "react-google-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { useSnackbar } from "notistack";

import { Stack, Divider, Typography } from "@mui/material";

import googleIcon from "../../assets/icons/google.svg";
import faceBookIcon from "../../assets/icons/facebook.svg";

import CommonButton from "./Button";

import { isEmailExist, login, signup } from "../../actions/authentication-actions";
import { getUserData } from "../../actions/user-actions";
import { QuestionApis } from "../../api/question-api";

export default function SocialAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { signIn, loaded } = useGoogleLogin({
    // Live client id 
    // clientId: "223737655312-0hm3ns2odd8moijh88b36fquvj2uevsp.apps.googleusercontent.com",
    clientId: "223737655312-0hm3ns2odd8moijh88b36fquvj2uevsp.apps.googleusercontent.com",
    buttonText: "",
    onSuccess: (data) => {
      dispatch(
        isEmailExist(data?.profileObj?.email, (result) => {
          result = result[0].data;
          if (result.length === 0) {
            dispatch(
              signup(
                {
                  email: data?.profileObj?.email,
                  name: data?.profileObj?.name,
                  profileImage: data?.profileObj?.imageUrl,
                  password: data?.googleId,
                },
                () => {
                  enqueueSnackbar("Successfully Signup", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "success",
                  });
                  dispatch(
                    login(
                      {
                        email: data?.profileObj?.email,
                        password: data?.googleId,
                      },
                      (result) => {
                        if (result && result?.data?.authToken?.token) {
                          localStorage.setItem("LoginToken", result?.data?.authToken?.token);
                          localStorage.setItem("refID", result?.data.user.id);
                          dispatch(getUserData(data?.profileObj?.email));
                          QuestionApis.getAllQuestions(result?.data?.user.id).then((res) => {
                            navigate(`/dashboard/answer-question/${res?.data[0].id}`, { replace: true });
                          });
                        }
                      }
                    )
                  );
                  localStorage.setItem("currentUserEmail", data?.profileObj?.email);
                  localStorage.setItem("emailID", data?.profileObj?.email);
                }
              )
            );
          } else {
            dispatch(
              login(
                {
                  email: data?.profileObj?.email,
                  password: data?.googleId,
                },
                (result) => {
                  if (result && result?.data?.authToken?.token) {
                    localStorage.setItem("LoginToken", result?.data?.authToken?.token);
                    localStorage.setItem("refID", result?.data.user.id);
                    localStorage.setItem("currentUserEmail", data?.profileObj?.email);
                    localStorage.setItem("emailID", data?.profileObj?.email);
                    dispatch(getUserData(data?.profileObj?.email));
                    QuestionApis.getAllQuestions(result?.data?.user.id).then((res) => {
                      navigate(`/dashboard/answer-question/${res?.data[0].id}`, { replace: true });
                      enqueueSnackbar("Login Successful", {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                        variant: "success",
                      });
                    });

                  } else {
                    enqueueSnackbar("Login Failed", {
                      anchorOrigin: {
                        horizontal: "right",
                        vertical: "top",
                      },
                      variant: "error",
                    });
                  }
                }
              )
            );
          }
        })
      );
    },
    onFailure: (data) => {
      console.log(data);
    },
    cookiePolicy: "single_host_origin",
  });

  const responseFacebook = (response) => {
    if (response?.status !== "unknown") {
      dispatch(
        isEmailExist(response?.email, (result) => {
          result = result[0].data;
          if (result.length === 0) {
            dispatch(
              signup(
                {
                  email: response.email,
                  name: response.name,
                  password: response.id

                },
                () => {
                  enqueueSnackbar("Successfully Signup", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "success",
                  });
                  dispatch(
                    login(
                      {
                        email: response.email,
                        password: response.id,
                      },
                      (result) => {
                        if (result && result?.data?.authToken?.token) {
                          localStorage.setItem("LoginToken", result?.data?.authToken?.token);
                          localStorage.setItem("refID", result?.data.user.id);
                          dispatch(getUserData(response.email));
                          QuestionApis.getAllQuestions(result?.data.user.id).then((res) => {
                            navigate(`/dashboard/answer-question/${res?.data[0].id}`, { replace: true });
                          });
                        }
                      }
                    )
                  );
                  localStorage.setItem("currentUserEmail", response.email);
                  localStorage.setItem("emailID", response.email);
                }
              )
            );
          } else {
            dispatch(
              login(
                {
                  email: response.email,
                  password: response.id,
                },
                (result) => {
                  if (result && result?.data?.authToken?.token) {
                    localStorage.setItem("LoginToken", result?.data?.authToken?.token);
                    localStorage.setItem("refID", result?.data.user.id);
                    localStorage.setItem("currentUserEmail", response.email);
                    localStorage.setItem("emailID", response.email);
                    dispatch(getUserData(response.email));
                    QuestionApis.getAllQuestions(result?.data.user.id).then((res) => {
                      navigate(`/dashboard/answer-question/${res?.data[0].id}`, { replace: true });
                      enqueueSnackbar("Login Successful", {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                        variant: "success",
                      });
                    });

                  } else {
                    enqueueSnackbar("Login Failed", {
                      anchorOrigin: {
                        horizontal: "right",
                        vertical: "top",
                      },
                      variant: "error",
                    });
                  }
                }
              )
            );
          }
        })
      );
    }
  }
  return (
    <>
      <Divider sx={{ color: "#BDBDBD" }}>
        <Typography variant="body2" sx={{ marginX: 3 }}>
          Or
        </Typography>
      </Divider>
      <Stack direction="row" spacing={4} sx={{ justifyContent: "center" }}>
        <CommonButton
          size={"large"}
          color={"success"}
          variant={"outlined"}
          style={{ outline: "1px solid", paddingX: 4 }}
          key="google"
          onClick={signIn}
        >
          <img src={googleIcon} alt="" style={{ height: 40 }} />
        </CommonButton>

        <FacebookLogin
          appId="3073908552832343"
          callback={responseFacebook}
          fields="name,email"
          render={renderProps => (
            <CommonButton
              size={"large"}
              color={"success"}
              variant={"outlined"}
              style={{ outline: "1px solid", paddingX: 4 }}
              key="facebook"
              onClick={renderProps.onClick}
            >
              <img src={faceBookIcon} alt="" style={{ height: 40 }} />
            </CommonButton>
          )}
        />

      </Stack>
    </>
  );
}
