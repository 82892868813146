import { Button } from "@mui/material";

export default function CommonButton({
  fullWidth,
  className,
  children,
  variant,
  type,
  color,
  style,
  size,
  onClick,
  disabled,
}) {
  return (
    <Button
      className={className}
      fullWidth={fullWidth}
      color={color}
      type={type}
      variant={variant}
      sx={style}
      size={size}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
