import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import LogoImg from "../../assets/logos/Logo.svg";

export default function Logo(props) {
  const navigate = useNavigate();
  const { allQuestions } = useSelector((state) => state.questionReducer);
  const LoginToken = localStorage.getItem("LoginToken");

  return (
    <img
      src={props.LogoImg}
      alt=""
      onClick={() => {
        if (LoginToken)
          navigate(`/dashboard/answer-question/${allQuestions[0]?.ref?.value?.id}`, { replace: true });
      }}
    />
  );
}
