import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Box, TextField, Grid, Stack, Link, Typography, Divider, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import LogoName from "../../assets/logos/Logo.svg";
import Logo from "../../components/common/Logo";

import AnimationImage from "../../components/common/AnimationImage";

const useStyles = makeStyles((theme) => ({
  bodyScroll: {
    [theme.breakpoints.down("900")]: {
      overflowY: "auto",
    }
  },
  divider: {
    display: "none",
    [theme.breakpoints.down("900")]: {
      display: "block",
      border: "1px solid #009A4E",
      width: "100%",
      marginTop: "10px",
    },
  },
}));

export default function PrivacyPolicy() {
  const classes = useStyles();
  const navigate = useNavigate();
 
 
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.bodyScroll}>
            <Box sx={{ marginX: 10, paddingTop: 5 }} className="formlayout">
              <Box className="logoDiv">
                <Logo LogoImg={LogoName} />    
                <Box className="links">
                  <Link className="link_login_signup" to="/" component={RouterLink}>
                    Go to application
                  </Link>
                </Box>           
              </Box>
            </Box>
          </Box>
          </Grid>
          <Grid item lg={1} md={1}></Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Box sx={{ marginX: 10, paddingBottom: 5, marginTop: 10 }} className="formlayout">
                <Box>
                  <Box className="head_login_signup">
                    <Typography className="termsH1">Privacy Policy for MindTap</Typography>
                  </Box>
                  <Box className="head_login_signup">
                    <Typography className="termsH2">Effective date: Jan, 1st 2023</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    At MindTap, we respect the privacy of our users and are committed to protecting their
personal information. This privacy policy explains how we collect, use, and protect your
information when you use our website www.mindtap.app or any of our services
(collectively, the "Services").
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    By using our Services, you agree to the terms of this Privacy Policy. If you do not agree
to the terms of this Privacy Policy, please do not use our Services.
                    </Typography>
                  </Box>
                  <Box className="head_login_signup">
                    <Typography className="termsH3">1. Information we collect</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    We collect personal information that you provide to us when you sign up for our
Services, including your name, email address, and password. We may also collect other
information that you voluntarily provide to us, such as your phone number, mailing
address, or payment information.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    We may also automatically collect certain information about your use of our Services,
such as your IP address, browser type, and operating system. We may use cookies, web
beacons, and other tracking technologies to collect this information.
                    </Typography>
                  </Box>

                  <Box className="head_login_signup">
                    <Typography className="termsH3">2. How we use your information</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    We use the information we collect to provide, maintain, and improve our Services, as
well as to communicate with you about our Services. For example, we may use your
email address to send you updates about our Services or to respond to your inquiries.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    We may also use your information to personalize your experience on our website or to
offer you targeted advertising. We may share your information with third-party
advertisers to show you relevant ads.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    We will not sell or rent your personal information to third parties for their marketing
purposes without your consent.
                    </Typography>
                  </Box>



                  <Box className="head_login_signup">
                    <Typography className="termsH3">3. How we protect your information</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    We take the security of your personal information seriously and take reasonable
measures to protect it from unauthorized access, use, or disclosure. We useindustry-standard security measures, such as encryption and firewalls, to protect your
information.
                    </Typography>
                  </Box>
                  

                <Box className="head_login_signup">
                    <Typography className="termsH3">4. Children's privacy</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    Our Services are not directed to children under the age of 13, and we do not knowingly
collect personal information from children under the age of 13. If we become aware that
we have collected personal information from a child under the age of 13, we will delete
that information as quickly as possible.
                    </Typography>
                  </Box>
                  

                  <Box className="head_login_signup">
                    <Typography className="termsH3">5. Changes to this privacy policy</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText">
                    We reserve the right to modify this Privacy Policy at any time. If we make material
changes to this Privacy Policy, we will notify you by email or by posting a notice on our
website prior to the effective date of the changes. Your continued use of our Services
after the effective date of the changes constitutes your acceptance of the new Privacy
Policy.
                    </Typography>
                  </Box>       

                   <Box className="head_login_signup">
                    <Typography className="termsH3">6. Contact us</Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText lastTermsText">
                    If you have any questions or concerns about this Privacy Policy or our data practices,
please contact us at contact@mindtap.app.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="termsText lastTermsText">
                    Last updated: Jan, 1st 2023
                    </Typography>
                  </Box>  
                  </Box>             
            </Box>
          </Grid>
      </Grid>
    </Box>
  );
}
