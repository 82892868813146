import { SERVER_URL } from '../App.config';
import { request } from '../utils/axios';

const getCurrentUser = (email) => {
    return request(SERVER_URL + `/users/${email}`, 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

const getUserPreferencesData = () => {
    return request(SERVER_URL + '/users/getUserPreferenceData', 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

const updateUserPreferences = (topicPreference) => {
    return request(SERVER_URL + '/users/updateUserPreferenceData', 'post', { topicPreference })
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
}

const updateUserData = (refId, updatedData) => {
    let data = {
        userId: refId,
        firstName: updatedData.name,
        phone: updatedData.phone,
        CountryId: updatedData.country.id,
        gender: updatedData.gender,
        age: updatedData.age,
        RaceId: updatedData.race.id,
        region: updatedData.region,
        reputation: updatedData.reputation,
        profileImage: updatedData.profileImage,
        topicPreference: updatedData.topicPreference
    }
    return request(SERVER_URL + `/users/${refId}`, 'put', data)
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

export const checkEmailExist = (email) => {
  return request(SERVER_URL + `/users/${email}`, 'get')
  .then((response) => [response])
  .catch((error) => {
    throw error;
  });
};

export const UserApis = {
    getCurrentUser,
    getUserPreferencesData,
    updateUserPreferences,
    updateUserData,
    checkEmailExist
}