import { useEffect, useState } from "react";
import React from 'react'
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  Divider,

} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import AwesomeSlider from "react-awesome-slider";

import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FireIcon from "../../../assets/icons/burn.svg";
import MarkIcon from "../../../assets/icons/mark.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import CommonButton from "../../../components/common/Button";
import { InlineShareButtons } from "sharethis-reactjs";

import _ from 'underscore';
import { Chart as ChartJS, ArcElement, Tooltip as ChartToolTip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  getQuestions,
  responseToQuestion,
  setSelectedQuestionState,
  removeQuestion,
  skipRemind,
  reportCurrentQuestion,
} from "../../../actions/questions-actions";
import { setTopics, setSubcategory } from "../../../actions/topic-action";
import { QuestionApis } from "../../../api/question-api";
import { RatingApis } from "../../../api/rating-api";
import { MediaApis } from "../../../api/media-api";

ChartJS.register(ArcElement, ChartToolTip, Legend, ChartDataLabels);
const reportText = [
  { label: "Doesn't answer the question" },
  { label: "Spam" },
  { label: "Plagiarism" },
  { label: "Harassment" },
  { label: "Joke answer" },
  { label: "Poorly written" },
  { label: "Inappropriate credential" },
  { label: "Bad image" },
  { label: "Adult content" },
  { label: "Factually incorrect" },
  { label: "Other" },
];

const useStyles = makeStyles((theme) => ({
  YesNoButton: {
    width: "180px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.down("655")]: {
      width: "110px",
    },
    [theme.breakpoints.down("485")]: {
      width: "90px",
      marginRight: "5px",
    },
    [theme.breakpoints.down("396")]: {
      width: "80px",
      marginRight: "4px",
    },
    [theme.breakpoints.down("341")]: {
      height: "35px",
      width: "130px",
    },
  },
  responseButton: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "5px",
    },
  },

  link: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "30px !important",
    marginBottom: "30px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5px",
    },
  },
  relatedQue: {
    margin: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: "120px",
    },
    [theme.breakpoints.down("341")]: {
      marginTop: "20px",
      marginBottom: "160px",
    },
  },
  uploadInput: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    padding: "8px",
    "&$focused": {
      border: "1px solid #009A4E",
    },
  },
  categorySubcategory: {
    color: "#2F80ED",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "17px",
  },
  categoryAndIcons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
  },
  averageRating: {
    color: "#EB5757",
    fontWeight: 600,
    fontSize: "25px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
  },
  inputBoxCSS: {
    backgroundColor: "#FFF",
    border: 0,
    outline: "none",
    padding: "8px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
  },
  reportLabel: {
    padding: "10px 10px",
    borderRadius: "5px",
    transition: "all 0.3s",
    cursor: "pointer",
    fontWeight: "bold",
  },
  dialogTitle: {
    margin: "5px",
    fontWeight: 600,
    fontSize: "25px",
    fontFamily: "Montserrat",
  },
  reactSlickSlider: {
    borderRadius: "10px",
  },
  awesomeSlider: {
    "&  div.awssld__content": {
      backgroundColor: "transparent !important",
      zIndex: 1,
    },
  },
  previousResponse: {
    color: "#3080ed",
    fontWeight: 600,
    fontSize: "16px",
    marginBottom: "10px",
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  specialOutline: {
    border: "none",
  },
  remindLaterButton: {
    [theme.breakpoints.down("339")]: {
      height: "30px",
      width: "80px",
      marginRight: "5px",
    },
    [theme.breakpoints.down("300")]: {
      height: "30px",
      width: "55px",
      marginRight: "1px",
    },
  },
  relatedQuestionPadding: {
    [theme.breakpoints.down("xs")]: {
      paddingInlineStart: "15px",
      marginBottom: "5px",
    },
  },
}));

export default function AnswerQuestion() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedQuestion, relatedQuestions, allQuestions } = useSelector((state) => state.questionReducer);

  const { topics, subCategories } = useSelector((state) => state.topicReducer);
  const [otherReport, setOtherReport] = useState("");
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [reportData, setreportData] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [value, setValue] = useState(null);
  const [ratingId, setRatingId] = useState(null);
  const [hover, setHover] = useState(-1);
  const [mediaURLs, setMediaURLs] = useState([]);
  const [dropDown, setDropDown] = useState([]);
  const [genderYesChart, setGenderYesChart] = useState({});
  const [genderNoChart, setGenderNoChart] = useState({});
  const [regionYesChart, setRegionYesChart] = useState({});
  const [regionNoChart, setRegionNoChart] = useState({});
  const [ageNoChart, setAgeNoChart] = useState({});
  const [ageYesChart, setAgeYesChart] = useState({});

  const userID = localStorage.getItem("refID");
  const loginToken = localStorage.getItem("LoginToken");  

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const onChangeHandle = async value => {
  // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions 
  
      QuestionApis.searchQuestion(value).then(async(response)=>{
        const questions = response.data;
        setOptions(Object.keys(questions).map((item)=> { return { id: questions[item].id, name: questions[item].title  }}))
      })
  
      
    };

  useEffect(() => {
    if (!open) {
      let temp = []
      allQuestions.map((currentQuestion)=>{
        temp.push({ id: currentQuestion.id, name: currentQuestion.title });
      })
      setOptions(temp)
    }
  }, [open]);

  useEffect(() => {
    let id = location?.pathname?.split("/")[3];
    if(id && id != 'undefined') {
      QuestionApis.currentQuestion(id).then((response) => {
        dispatch(setSelectedQuestionState(response, userID));
      });
    }
    dispatch(setTopics())
    dispatch(setSubcategory());
    if(id && id != 'undefined') {
      setReportDataForTheQuestion(id)
    }
    //dispatch(setReportDataForTheQuestion(location?.pathname?.split("/")[3]))
  }, [location.pathname]);

  useEffect(() => {
    if (location?.state) {
      let id = location?.pathname?.split("/")[3];
      if(id && id != 'undefined') {
        QuestionApis.currentQuestion(location?.pathname?.split("/")[3]).then((response) => {
          dispatch(setSelectedQuestionState(response, userID));
        });
      }
    } else if (!(allQuestions && allQuestions.length > 0)) {
      dispatch(getQuestions(userID));
    }
    dispatch(setTopics())
  }, []);

  useEffect(() => {
    let topicSubTopic = [];
    for (let i = 0; i < topics.length; i++) {
      let subCategory = _.where(subCategories, { CategoryId: topics[i].categoryid });
      for (let j = 0; j < subCategory.length; j++) {
        topicSubTopic.push({ category: topics[i]['category'], subcategory: subCategory[j].name, categoryId: topics[i].categoryid, subcategoryId: subCategory[j].id })
      }
    }
    setDropDown(topicSubTopic)
  }, [topics]);

  useEffect(() => {
    if (loginToken) {
      if (!(allQuestions && allQuestions.length > 0)) {
        dispatch(getQuestions(userID));
      }
      if (allQuestions && allQuestions.length > 0 && !location?.state) {
          navigate(`/dashboard/answer-question/${allQuestions[0].id}`, { replace: true });
          QuestionApis.currentQuestion(allQuestions[0].id).then((response) => {
            dispatch(setSelectedQuestionState(response, userID));
          });
      }
    } else {
      let id = location?.pathname?.split("/")[3];
      if(id && id != 'undefined') {
        QuestionApis.currentQuestion(id).then((response) => {
          dispatch(setSelectedQuestionState(response, userID));
        });
      }
    }
  }, [allQuestions]);

  useEffect(() => {
    if (selectedQuestion?.data?.media && selectedQuestion?.data?.media.length > 0) {
      MediaApis.getMediaURL({ keys: selectedQuestion?.data?.media.map((item) => item.Key) })
        .then((res) => {
          let mediaObjArray = [];
          for (let i = 0; i < selectedQuestion?.data?.media.length; i++) {
            mediaObjArray.push({
              ...selectedQuestion?.data?.media[i],
              mediaUrl: res.data[i],
            });
          }
          setMediaURLs(mediaObjArray);
        })
        .catch((error) => error);
    }
    if(selectedQuestion?.id) {
      RatingApis.setRatingAverage(selectedQuestion?.id);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    RatingApis.getQuestionRating(selectedQuestion?.id, userID).then((result) => {
      setValue(result?.data?.ratings);
      setRatingId(result?.data?.id);
    });
  }, [selectedQuestion]);

  const checkIfRatingIsAvailableOrNot = (newValue) => {
    if (value !== undefined) {
      RatingApis.updateRating(ratingId, newValue).then((response) => {
        RatingApis.setRatingAverage(selectedQuestion?.id);
      });
    } else {
      RatingApis.createRating(userID, selectedQuestion?.id, newValue).then((response) => {
        RatingApis.setRatingAverage(selectedQuestion?.id);
      });
    }
  };

  const setReportDataForTheQuestion = (questionId) => {
    QuestionApis.getReportDataForQuestion(questionId).then((response) => {
       setreportData(response.data)
    });
  }

  const questionResponse = (event) => {
    if (loginToken) {
      if (location?.state?.answer.length > 0) {
        dispatch(
          responseToQuestion(userID, event.target.innerText, selectedQuestion, () => {
            dispatch(removeQuestion(selectedQuestion));
            enqueueSnackbar("Response Updated", {
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
              variant: "success",
            });
            navigate(`/dashboard/answer-question/${allQuestions[1]?.id}`, { replace: true });
          })
        );
      } else {
        dispatch(
          responseToQuestion(userID, event.target.innerText, selectedQuestion, () => {
            dispatch(removeQuestion(selectedQuestion));
            enqueueSnackbar("Your Response Recorded Successfully", {
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
              variant: "success",
            });
            navigate(`/dashboard/answer-question/${allQuestions[1]?.id}`, { replace: true });
          })
        );
      }
    } else {
      enqueueSnackbar("Login To MindTap", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "warning",
        style: { top: "90px" },
      });
    }
  };
  const remindLater = (event) => {
    if (loginToken) {
      dispatch(skipRemind(selectedQuestion, userID, event.target.innerText));
      navigate(`/dashboard/answer-question/${allQuestions[1]?.ref?.value?.id}`, { replace: true });
    } else {
      enqueueSnackbar("Login To MindTap", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "warning",
        style: { top: "90px" },
      });
    }
  };

  const skipQuestion = (skipEvent) => {
    if (loginToken) {
      dispatch(skipRemind(selectedQuestion, userID, skipEvent?.target?.innerText));
      navigate(`/dashboard/answer-question/${allQuestions[1]?.id}`, { replace: true });
    } else {
      enqueueSnackbar("Login To MindTap", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "warning",
        style: { top: "90px" },
      });
    }
  };

  const searchQuestion = (option) => {
    if(option && !_.isEmpty(option)) {
      navigate(`/dashboard/answer-question/${option.id}`, { replace: true });
    }
  };

  const handleReportDialog = (isOpen) => {
    if (loginToken) {
      setReportDialogOpen(isOpen);
      setReportReason("");
    } else
      enqueueSnackbar("Login To MindTap", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "warning",
        style: { top: "90px" },
      });
  };

  const handleSubmitReport = (reason) => {
    if (reason) {
      dispatch(
        reportCurrentQuestion(selectedQuestion, userID, "Report", reason, () =>
          enqueueSnackbar("Question Reported", {
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            variant: "success",
            style: { top: "90px" },
          })
        )
      );
    } else {
      dispatch(
        reportCurrentQuestion(selectedQuestion, userID, "Report", reportReason, () =>
          enqueueSnackbar("Question Reported", {
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            variant: "success",
            style: { top: "90px" },
          })
        )
      );
    }
    handleReportDialog(false);
    setOtherReport("");
  };

  const handelSelectReport = (event) => {
    setReportReason(event.target.value);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otherReport: "",
    },
  });

  useEffect(()=>{
    const pieGenericData = {
      labels: ['No', 'Yes'],      
      datasets: [
        {
          label: '# of Votes',
          data: [4,2],
          backgroundColor: [
            'rgba(255, 99, 132, 0.7)',
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 206, 86, 0.7)',
            'rgba(75, 192, 192, 0.7)',
            'rgba(153, 102, 255, 0.7)',
            'rgba(255, 159, 64, 0.7)',
            'rgba(255, 200, 155, 0.7)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    
    /* 
    difference percepative chart js
    let femaleNoData = _.where(reportData?.reports?.genderWiseData, { gender: 'Female', answer: 'NO' })
    let maleNoData = _.where(reportData?.reports?.genderWiseData, { gender: 'Male', answer: 'NO' })
    let femaleYesData = _.where(reportData?.reports?.genderWiseData, { gender: 'Female', answer: 'YES' })
    let maleYesData = _.where(reportData?.reports?.genderWiseData, { gender: 'Male', answer: 'YES' })

    let femalePieData = JSON.parse(JSON.stringify(pieGenericData));
    femalePieData.datasets[0].data = [(femaleNoData && femaleNoData.length ) ? parseFloat(femaleNoData[0].total) : 0, (femaleYesData && femaleYesData.length ) ? parseFloat(femaleYesData[0].total) : 0]

    setFemalChart(femalePieData);

    let malePieData = JSON.parse(JSON.stringify(pieGenericData));
    malePieData.datasets[0].data = [(maleNoData && maleNoData.length ) ? parseFloat(maleNoData[0].total) : 0, (maleYesData && maleYesData.length ) ? parseFloat(maleYesData[0].total) : 0]

    setMaleChart(malePieData) */


    /*start gender chart configuration*/
    let genderYesData = _.where(reportData?.reports?.genderWiseData, { answer: 'YES' })
    genderYesData = _.sortBy(genderYesData, 'gender');
    let genderYesLabel = genderYesData.map((currentElement)=>{
      return currentElement.gender
    })
    let genderYesValue = genderYesData.map((currentElement)=>{
      return currentElement.total
    })
    let genderYesPieChart = JSON.parse(JSON.stringify(pieGenericData));
    genderYesPieChart.labels = genderYesLabel;
    genderYesPieChart.datasets[0].data = genderYesValue
    setGenderYesChart(genderYesPieChart);

    let genderNoData = _.where(reportData?.reports?.genderWiseData, { answer: 'NO' })
    genderNoData = _.sortBy(genderNoData, 'gender');
    let genderNoLabel = genderNoData.map((currentElement)=>{
      return currentElement.gender
    })
    let genderNoValue = genderNoData.map((currentElement)=>{
      return currentElement.total
    })
    let genderNoPieChart = JSON.parse(JSON.stringify(pieGenericData));
    genderNoPieChart.labels = genderNoLabel;
    genderNoPieChart.datasets[0].data = genderNoValue
    setGenderNoChart(genderNoPieChart);
    /*end gender chart configuration*/


    /* start region chart configuration */
    let regionYesTotalCount = 0, regionNoTotalCount = 0;
    let regionYesData = _.where(reportData?.reports?.regionWiseData, { answer: 'YES' })
    regionYesData = _.sortBy(regionYesData, 'region');
    let regionYesLabel = regionYesData.map((currentElement)=>{
      return currentElement.region
    })
    let regionYesValue = regionYesData.map((currentElement)=>{
      regionYesTotalCount = parseInt(regionYesTotalCount) + parseInt(currentElement.total);
      return currentElement.total
    })
    regionYesValue = regionYesValue.map((currentValue)=>{
      return parseFloat(100 * (currentValue / regionYesTotalCount)).toFixed(2);
    })
    let regionYesPieChart = JSON.parse(JSON.stringify(pieGenericData));
    regionYesPieChart.labels = regionYesLabel;
    regionYesPieChart.datasets[0].data = regionYesValue
    setRegionYesChart(regionYesPieChart);


    let regionNoData = _.where(reportData?.reports?.regionWiseData, { answer: 'NO' })
    regionNoData = _.sortBy(regionNoData, 'region');
    let regionNoLabel = regionNoData.map((currentElement)=>{
      return currentElement.region
    })
    let regionNoValue = regionNoData.map((currentElement)=>{
      regionNoTotalCount = parseInt(regionNoTotalCount) + parseInt(currentElement.total);
      return currentElement.total
    })
    regionNoValue = regionNoValue.map((currentValue)=>{
      return parseFloat(100 * (currentValue / regionNoTotalCount)).toFixed(2);
    })
    let regionNoPieChart = JSON.parse(JSON.stringify(pieGenericData));
    regionNoPieChart.labels = regionNoLabel;
    regionNoPieChart.datasets[0].data = regionNoValue
    setRegionNoChart(regionNoPieChart);
    /* end  region chart configuration */

    /* start age chart configuration */
    let ageYesTotalCount = 0, ageNoTotalCount = 0;
    let ageYesData = reportData?.reports?.ageWiseData
    ageYesData = _.sortBy(ageYesData, 'order');
    let ageYesLabel = ageYesData.map((currentElement)=>{
      return currentElement.label
    })
    let ageYesValue = ageYesData.map((currentElement)=>{
      let temp = _.where(currentElement.value, { answer: 'YES' })
      if(temp && temp.length) {
        ageYesTotalCount = parseInt(ageYesTotalCount) + parseInt(temp[0].total);
        return temp[0].total;
      } else return 0
    })
    ageYesValue = ageYesValue.map((currentValue)=>{
      return parseFloat(100 * (currentValue / ageYesTotalCount)).toFixed(2);
    })
    let ageYesPieChart = JSON.parse(JSON.stringify(pieGenericData));
    ageYesPieChart.labels = ageYesLabel;
    ageYesPieChart.datasets[0].data = ageYesValue
    setAgeYesChart(ageYesPieChart);


    let ageNoData = reportData?.reports?.ageWiseData
    ageNoData = _.sortBy(ageNoData, 'order');
    let ageNoLabel = ageNoData.map((currentElement)=>{
      return currentElement.label
    })
    let ageNoValue = ageNoData.map((currentElement)=>{
      let temp = _.where(currentElement.value, { answer: 'NO' })
      if(temp && temp.length) {
        ageNoTotalCount = parseInt(ageNoTotalCount) + parseInt(temp[0].total);
        return temp[0].total;
      } else return 0
    })
    ageNoValue = ageNoValue.map((currentValue)=>{
      return parseFloat(100 * (currentValue / ageNoTotalCount)).toFixed(2);
    })
    let ageNoPieChart = JSON.parse(JSON.stringify(pieGenericData));
    ageNoPieChart.labels = ageNoLabel;
    ageNoPieChart.datasets[0].data = ageNoValue
    setAgeNoChart(ageNoPieChart);
    /* end  age chart configuration */

  }, [location.pathname, reportData])

  const LoadPieCharts = () => {
    const options = {
      showAllTooltips: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          font: {
            size: 10
          },
          color: '#FFFFFF',
          formatter: function(value, context) {
            return value + '%';
          }
        }
      }   
    }

    return (
      <>
       <Box sx={{ marginLeft: 5, marginBottom: 5}} >
          <Box>
            <Typography
              sx={{
                color: "#000000",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
                fontFamily: "Montserrat",
              }}
            >
              Gender wise report
            </Typography>
          </Box>
        </Box>
        <Grid container={'true'} item sx={12}>
            
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box sx={{ marginLeft: 48, fontSize: 10 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontFamily: "Montserrat",
                }}
              >
                YES
              </Typography>
            </Box>
            <Box>
                {!_.isEmpty(genderYesChart) &&
                    <Pie redraw={true} data={genderYesChart} width={"50%"}  options={options}/>
                }          
            </Box>
                
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box sx={{ marginLeft: 48, fontSize: 10 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontFamily: "Montserrat",
                }}
              >
                NO
              </Typography>
            </Box>
            <Box>
              {!_.isEmpty(genderNoChart) &&
                  <Pie redraw={true} data={genderNoChart} width={"50%"}  options={options}/>
                }
            </Box> 
          </Grid>
        </Grid>
        <Box sx={{ marginLeft: 5, marginTop: 5, marginBottom: 5}} >
          <Box>
            <Typography
              sx={{
                color: "#000000",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
                fontFamily: "Montserrat",
              }}
            >
              Region wise report
            </Typography>
          </Box>
        </Box>
        <Grid container={'true'} item sx={12}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ marginLeft: 56, fontSize: 10 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontFamily: "Montserrat",
                }}
              >
                YES
              </Typography>
            </Box>
            <Box>
                  {!_.isEmpty(regionYesChart) &&
                      <Pie redraw={true} data={regionYesChart} width={"50%"}  options={options}/>
                  }          
             </Box>     
                  
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ marginLeft: 56, fontSize: 10 }}>
                <Typography
                    sx={{
                      color: "#000000",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    NO
                  </Typography>
              </Box>
              <Box>
                {!_.isEmpty(regionNoChart) &&
                    <Pie redraw={true} data={regionNoChart} width={"50%"}  options={options}/>
                  } 
                  </Box>
          </Grid>
        </Grid>

        <Box sx={{ marginLeft: 5, marginTop: 5, marginBottom: 5}} >
          <Box>
            <Typography
              sx={{
                color: "#000000",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
                fontFamily: "Montserrat",
              }}
            >
              Age wise report
            </Typography>
          </Box>
        </Box>
        <Grid container={'true'} item sx={{ marginBottom: { lg: "200px", xs: "30px" } }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ marginLeft: 58, fontSize: 10 }}>
                  <Typography
                      sx={{
                        color: "#000000",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "18px",
                        lineHeight: "22px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      YES
                    </Typography>
                </Box>
                <Box>
                  {!_.isEmpty(ageYesChart) &&
                      <Pie redraw={true} data={ageYesChart} width={"50%"}  options={options}/>
                  }          
               </Box>   
                  
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ marginLeft: 58, fontSize: 10 }}>
                      <Typography
                          sx={{
                            color: "#000000",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "18px",
                            lineHeight: "22px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          NO
                        </Typography>
                </Box>
                <Box>
                 {!_.isEmpty(regionNoChart) &&
                    <Pie redraw={true} data={ageNoChart} width={"50%"}  options={options}/>
                  } 
                </Box> 
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ margin: 5, marginBottom: { lg: "70px", xs: "30px" } }} >
            <Box>
              <Autocomplete
                id="searchQuestion"
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={option => option.name}
                onChange={(event, value) => searchQuestion(value)}
                options={options}
                loading={loading}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Search questions"
                    variant="outlined"
                    onChange={ev => {
                      // dont fire API if the user delete or not entered anything
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        onChangeHandle(ev.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        root: classes.uploadInput,
                        focused: classes.focused,
                      },
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 5 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Question
                  </Typography>
                </Box>

                <Box>
                  <CommonButton
                    variant={"outlined"}
                    className={classes.remindLaterButton}
                    style={{
                      borderRadius: "50px",
                      color: "#828282",
                      textTransform: "none",
                      border: "1px solid #828282",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      marginRight: "20px",
                      lineHeight: "15px",
                      "&:hover": {
                        backgroundColor: "#F8F8F8",
                        border: "1px solid #828282",
                      },
                    }}
                    onClick={(event) => remindLater(event)}
                  >
                    Remind later
                  </CommonButton>

                  <Link
                    onClick={(skipEvent) => skipQuestion(skipEvent)}
                    sx={{
                      textDecoration: "none",
                      color: "#2F80ED",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17px",
                      cursor: "pointer",
                    }}
                  >
                    Skip
                    <ArrowForwardIcon
                      sx={{
                        color: "#2F80ED",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "22px",
                        lineHeight: "17px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Link>
                </Box>
              </Box>

              <Box
                sx={{
                  height: "auto",
                  background: "#FFFFFF",
                  borderRadius: "10px",
                  marginTop: 2,
                  padding: 2,
                  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#4F4F4F",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "22px",
                      lineHeight: "27px",
                      fontFamily: "Montserrat",
                      overflowWrap: "break-word",
                      textAlign: "justify",
                      wordSpacing: { lg: "6px", sm: "0px" },
                    }}
                  >
                    {selectedQuestion?.title}
                  </Typography>
                </Box>

                <Box className={classes.categoryAndIcons}>
                  <Box className={classes.categorySubcategory}>
                    {selectedQuestion?.category} / {selectedQuestion?.subcategory}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Trend Question" arrow>
                      <IconButton>
                        <img src={FireIcon} alt="Fire Icon" />
                      </IconButton>
                    </Tooltip>

                    {selectedQuestion?.avgRating && (
                      <Tooltip title="Average Rating" arrow>
                        <Typography className={classes.averageRating}>
                          {selectedQuestion?.avgRating?.toFixed(1)}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
                <Box
                  sx={{
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="hover-feedback"
                    value={value}
                    precision={1}
                    onChange={(event, newValue) => {
                      if (loginToken) {
                        setValue(newValue);
                        checkIfRatingIsAvailableOrNot(newValue);
                      } else {
                        enqueueSnackbar("Login To MindTap", {
                          anchorOrigin: {
                            horizontal: "right",
                            vertical: "top",
                          },
                          variant: "warning",
                          style: { top: "90px" },
                        });
                      }
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                </Box>

                <IconButton onClick={() => handleReportDialog(true)}>
                  <Tooltip title="Report" arrow>
                    <img src={MarkIcon} alt="Explanation Mark Icon" />
                  </Tooltip>
                </IconButton>
              </Box>
            </Box>
            {selectedQuestion?.description && <Box>
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "22px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Description
                </Typography>
              </Box>

              <Box
                sx={{
                  height: "auto",
                  background: "#FFFFFF",
                  borderRadius: "10px",
                  marginTop: 2,
                  padding: 1,
                  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
                  marginBottom: selectedQuestion?.media?.length > 0 ? "" : "100px",

                }}
              >
                <Box sx={{ maxHeight: selectedQuestion?.media?.length > 0 ? "400px" : "500px", overflowY: "scroll" }}>
                  <TextField
                    multiline
                    fullWidth
                    value={selectedQuestion?.description}
                    InputProps={{
                      classes: { notchedOutline: classes.specialOutline },
                      readOnly: true,
                      style: {
                        fontSize: 40,
                        color: "#333333",
                        fontWeight: 600,
                        fontSize: "18px",
                        lineHeight: "30px",
                        fontFamily: "Montserrat",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>}
            {selectedQuestion?.media?.length > 0 && (
              <Box sx={{ mt: { lg: "16px", xs: "40px" } }}>
                <AwesomeSlider
                  bullets={false}
                  animation="cubeAnimation"
                  organicArrows={false}
                  buttonContentLeft={<ArrowBackIosNewIcon sx={{ color: "black" }} />}
                  buttonContentRight={<ArrowForwardIosIcon sx={{ color: "black" }} />}
                  className={classes.awesomeSlider}
                >
                  {mediaURLs?.map((imageURL, i) => {
                    const imageOrVideo = imageURL?.mediaUrl?.split("/");
                    if (imageOrVideo[3] === "image") {
                      return (
                        <Box className={classes.reactSlickSlider} key={i}>
                          <img
                            src={imageURL?.mediaUrl}
                            alt=""
                            height="300px"
                            width="100%"
                            style={{
                              borderRadius: "inherit",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return (
                        <Box className={classes.reactSlickSlider} key={i}>
                          <video controls style={{ height: "300px", width: "100%", borderRadius: "10px" }}>
                            <source src={imageURL?.mediaUrl} />
                          </video>
                        </Box>
                      );
                    }
                  })}
                </AwesomeSlider>
              </Box>
            )}
          </Box>
         
        </Grid>

        
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={classes.relatedQue}>
            {relatedQuestions?.length > 1 && <Box
              sx={{
                height: "auto",
                borderRadius: "10px",
                background: "#FFFFFF",
                padding: 2,
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Typography
                sx={{
                  color: "#2F80ED",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17px",
                  fontFamily: "Montserrat",
                }}
              >
                Related questions:
              </Typography>
              <Box sx={{ mt: 2, maxHeight: "550px", overflowY: "scroll" }}>
                <ol className={classes.relatedQuestionPadding}>
                  {relatedQuestions &&
                    relatedQuestions.length > 0 &&
                    relatedQuestions.map((question) => {
                      if (selectedQuestion.id !== question?.id) {
                        return (
                          (question?.isActive && <li
                            underline="none"
                            color="#000"
                            className={classes.link}
                            key={question.id}
                            onClick={() => {
                              navigate(`/dashboard/answer-question/${question?.id}`, {
                                replace: true,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {question?.title}
                          </li>)
                        );
                      }
                    })}
                </ol>
              </Box>
            </Box>}
            {/* <Box sx={{ marginY: 5 }} className="sharethis-inline-share-buttons"></Box> */}
            <Box sx={{ marginY: 5 }}>
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "white", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  labels: null, // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "whatsapp",
                    "sms",
                    "linkedin",
                    "email",
                    "facebook",
                    "twitter",
                    "sharethis",
                  ],
                  padding: 10, // padding within buttons (INTEGER)
                  radius: 100, // the corner radius on each button (INTEGER)
                  size: 50, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS
                  url: window.location.href, // (defaults to current url)
                  description: "MindTap", // (defaults to og:description or twitter:description)
                  title: "MindTap", // (defaults to og:title or twitter:title)
                  message: window.location.href, // (only for email sharing)
                  subject: "MindTap Question", // (only for email sharing)
                  username: "MindTap", // (only for twitter sharing)
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <LoadPieCharts></LoadPieCharts>
        </Grid>

      </Grid>

      <Dialog open={reportDialogOpen} onClose={() => handleReportDialog(false)}>
        <DialogTitle>
          <Typography className={classes.dialogTitle}>Report this question.</Typography>
        </DialogTitle>
        <Divider />

        <DialogContent sx={{ paddingBottom: "0px" }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={reportReason}
              onChange={handelSelectReport}
            >
              {reportText.map((item) => (
                <FormControlLabel value={item.label} control={<Radio />} label={item.label} />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>

        {reportReason === "Other" && (
          <Box sx={{ marginX: "20px", marginBottom: "15px" }}>
            <TextField
              color="success"
              name="otherReport"
              type="text"
              fullWidth
              value={otherReport}
              onChange={(event) => setOtherReport(event.target.value)}
              multiline
              rows={4}
              placeholder="Other Report..."
              InputProps={{
                style: {
                  color: "#000000",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontFamily: "Montserrat",
                  borderRadius: "10px",
                },
              }}
            />
          </Box>
        )}

        <DialogActions sx={{ borderTop: "1px solid #80808040" }}>
          <Button
            color="success"
            onClick={() => {
              handleReportDialog(false);
              setOtherReport("");
            }}
          >
            Cancel
          </Button>

          <Button
            color="success"
            disabled={(reportReason && reportReason !== "Other") || otherReport.length > 0 ? false : true}
            onClick={() => {
              if (otherReport.length > 0) {
                handleSubmitReport(otherReport);
              } else {
                handleSubmitReport();
              }
            }}
          >
            Report
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          bottom: 0,
          paddingY: "20px",
          width: "100%",
          background: "#f1f3ed",
          position: "fixed",
          zIndex: 10,
        }}
      >
        {location?.state?.question && location?.state?.answer && (
          <Typography
            className={classes.previousResponse}
          >{`Previous Response : ${location?.state?.answer}`}</Typography>
        )}
        <Box className={classes.responseButton}>
          <Box>
            <CommonButton
              className={classes.YesNoButton}
              variant={"contained"}
              type={"submit"}
              onClick={(event) => questionResponse(event)}
              style={{
                borderRadius: "10px",
                marginRight: 2,
                height: "44px",
                backgroundColor: "#009A4E",
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
                "&:hover": {
                  backgroundColor: "#009A4E",
                },
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                fontFamily: "Montserrat",
              }}
            >
              YES
            </CommonButton>

            <CommonButton
              className={classes.YesNoButton}
              variant={"contained"}
              type={"submit"}
              onClick={(event) => questionResponse(event)}
              style={{
                borderRadius: "10px",
                height: "44px",
                backgroundColor: "#EB5757",
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
                "&:hover": {
                  backgroundColor: "#EB5757",
                },
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                fontFamily: "Montserrat",
              }}
            >
              NO
            </CommonButton>
          </Box>
          {/* <Box>
            <CommonButton
              className={classes.YesNoButton}
              variant={"contained"}
              type={"submit"}
              onClick={(event) => questionResponse(event)}
              style={{
                borderRadius: "10px",
                height: "44px",
                marginRight: 2,
                backgroundColor: "#31a4fa",
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
                "&:hover": {
                  backgroundColor: "#31a4fa",
                },
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                fontFamily: "Montserrat",
              }}
            >
              MAYBE
            </CommonButton>
            <CommonButton
              className={classes.YesNoButton}
              variant={"contained"}
              type={"submit"}
              onClick={(event) => questionResponse(event)}
              style={{
                borderRadius: "10px",
                height: "44px",
                backgroundColor: "#f6af2f",
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
                "&:hover": {
                  backgroundColor: "#f6af2f",
                },
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                fontFamily: "Montserrat",
              }}
            >
              DON'T KNOW
            </CommonButton>
          </Box> */}
        </Box>
      </Box>
    </>
  );
}
