import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import LogoName from "../../../assets/logos/Logo.svg";
import FourOFour from "../../../assets/images/illustration_404.svg";
import Logo from "../../../components/common/Logo";

import CommonButton from "../../../components/common/Button";
import bodyBackground from "../../../assets/images/body_background.webp";

import { verifiedToken } from "../../../actions/authentication-actions";

const useStyles = makeStyles((theme) => ({
  messageOne: {
    color: "#000",
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginTop: "30px",
    marginBottom: "50px",
    letterSpacing: "9px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      letterSpacing: "2px",
    },
  },
  errorSvg: {
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
    },
  },
  svgBox: {
    [theme.breakpoints.down("sm")]: {
      margin: "0px 20px 0px",
    },
  },
}));

export default function VerifyEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useRef();
  const location = useLocation();

  useEffect(() => {
    dispatch(verifiedToken(location?.pathname?.split("/")[2], (response) => {
      console.log('response: ', response);
      localStorage.setItem("forgotEmail", response.email);
      navigate("/reset-password")
    },(error)=>{
      
    }));

  }, []);

  return (
    <>
      <Box sx={{ minHeight: "100vh", background: `URL(${bodyBackground})`, backgroundSize: "cover" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
            flexDirection: "column",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Logo LogoImg={LogoName} />
          </Box>
          <Box className={classes.svgBox}>
            <img src={FourOFour} className={classes.errorSvg} />
          </Box>

          <Box className={classes.messageOne}>The Token Has Expired</Box>

          <CommonButton
            variant={"contained"}
            type={"submit"}
            style={{
              padding: 1,
              bgcolor: "#009A4E",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#009A4E",
              },
            }}
            onClick={() => navigate("/forgot-password", { replace: true })}
          >
            Back To Forgot Password
          </CommonButton>
        </Box>
      </Box>
    </>
  );
}
