import * as React from "react";
import { useSelector } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader() {
  const { loaderState } = useSelector((state) => state.masterReducer);

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loaderState !== 0}>
        <CircularProgress color="inherit" size={60} />
      </Backdrop>
    </div>
  );
}
