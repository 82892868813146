import { MediaApis } from '../api/media-api';
import { UserApis } from '../api/user-api';
import { SET_USER_DETAIL, SET_USER_TOPIC_PREFERENCES } from './actionType';
import { setLoaderValue } from './master-actions';

const setUserData = (userData) => ({
  type: SET_USER_DETAIL,
  userData
});

const setUserPreferencesData = (userPreferencesData) => ({
  type: SET_USER_TOPIC_PREFERENCES,
  userPreferencesData
});



export const getUserData = (email) => (dispatch) => {
  dispatch(setLoaderValue(true));
  UserApis.getCurrentUser(email).then((result) => {
    dispatch(setUserData(result));
    dispatch(setLoaderValue(false));
  });
};

export const getUserPreferencesData = () => (dispatch) => {
  dispatch(setLoaderValue(true));
  UserApis.getUserPreferencesData().then((result) => {
    dispatch(setUserPreferencesData(result.data));
    dispatch(setLoaderValue(false));
  });
};

export const updateUserData = (userID, values, callBack) => (dispatch) => {
  dispatch(setLoaderValue(true));
  if (values?.profileImage && values?.profileImage.length > 0) {
    let mediaObject = [];
    for (let i = 0; i < values?.profileImage?.length; i++) {
      let formData = new FormData();
      formData.append('file', values?.profileImage[i]);
      let mediaObjectPromise = MediaApis.setImageVideoMedia(formData);
      mediaObject.push(mediaObjectPromise);
    }
    Promise.all(mediaObject)
      .then((data) => {
        return data.map((item) => item?.data);
      })
      .then((mediaArray) => {
        values.profileImage = mediaArray;
        UserApis.updateUserData(userID, values).then((result) => {
          callBack && callBack(result);
          dispatch(setLoaderValue(false));
        });
      })
      .catch((error) => {
        dispatch(setLoaderValue(false));
        console.log('error', error);
      });
  } else {
    UserApis.updateUserData(userID, values).then((result) => {
      callBack && callBack(result);
      dispatch(setLoaderValue(false));
    });
  }
};
