import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Backdrop } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import StarRateIcon from "@mui/icons-material/StarRate";

import { usersAnswers } from "../../actions/questions-actions";
import Paging from "../common/Paging";
import { IS_MOBILE } from "../../App.config";

const useStyles = makeStyles((theme) => ({
  yesBox: {
    borderRight: "7px solid #009A4E",
    borderLeft: "7px solid #009A4E",
  },
  noBox: {
    borderRight: "7px solid #EB5757",
    borderLeft: "7px solid #EB5757",
  },
  maybeBox: {
    borderRight: "7px solid #31a4fa",
    borderLeft: "7px solid #31a4fa",
  },
  dontknowBox: {
    borderRight: "7px solid #f6af2f",
    borderLeft: "7px solid #f6af2f",
  },
  answerBox: {
    background: "#FFF",
    height: "auto",
    padding: "20px !important",
    borderRadius: "15px 15px 15px 15px ",
    marginBottom: "20px !important",
    maxWidth: "700px",
  },
  NotAnswerQuestion: {
    background: "#FFF",
    padding: "20px !important",
    borderRadius: "15px 15px 15px 15px ",
    borderRight: "7px solid #009A4E",
    borderLeft: "7px solid #009A4E",
    marginBottom: "20px !important",
  },
  disabledBox: {
    pointerEvents: "none",
    opacity: 0.6,
    background: "#FFF",
    height: "auto",
    padding: "20px !important",
    borderRadius: "15px 15px 15px 15px ",
    marginBottom: "20px !important",
    borderRight: "7px solid #b0b0b0",
    borderLeft: "7px solid #b0b0b0",
    maxWidth: "700px",
  },
  question: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "22px",
    textAlign: "justify",
  },
  boxPadding: {
    padding: "80px 80px 20px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  categoryAndTime: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    marginTop: "20px"
  },

  categorySubcategory: {
    color: "#2F80ED",
    fontWeight: 600,
  },

  answerTime: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
  },
  descriptionEllipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    textAlign: "justify",
    marginTop: "10px",
    fontSize: "17px",
  },
}));

export default function MyAnswers() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAnswers } = useSelector((state) => state.questionReducer);

  const userID = localStorage.getItem("refID");

  const getAnswerType = (value) => {
    // return value === "YES" ? classes.greenBox : classes.redBox;
    if (value === "YES") return classes.yesBox;
    if (value === "NO") return classes.noBox;
    if (value === "MAYBE") return classes.maybeBox;
    if (value === "DON'T KNOW") return classes.dontknowBox;
  };

  /*  media pagination in fronted side ///////////////////////////////////*/
  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  /*  media pagination in fronted side //////////////////////////////////*/

  function timeStampToTime(timeStamp) {
    /* timeStamp = timeStamp.toString();
    timeStamp = timeStamp.slice(0, 13);
    const timeStampInSeconds = Number(timeStamp); */
    const time = moment(timeStamp).format("LLL");
    return time;
  }

  const displayQuestion = (questionId, value) => {
    navigate(`/dashboard/answer-question/${questionId}`, {
      state: {
        question: questionId,
        answer: value
      },
    });
  };

  useEffect(() => {
    dispatch(usersAnswers(userID));
  }, []);

  return (
    <>
      <Box className={classes.boxPadding}>
        {userAnswers &&
          userAnswers?.slice(pagination.start, pagination.end)?.map((answers, index) => (
            <Box
              className={!answers?.isActive ? classes.disabledBox : `${getAnswerType(answers?.value)}  ${classes.answerBox}`}
              key={index}
              onClick={() => displayQuestion(answers.questionId, answers.value)}
              sx={{ cursor: "pointer" }}
            >
              {!answers?.isActive && <Box sx={{ textAlign: "end", color: "black", fontWeight: 600, fontSize: "14px" }}>This question removed by user</Box>}
              <Box className={classes.question}>{answers?.title}</Box>
              <Box className={classes.descriptionEllipsis}>{answers?.description}</Box>
              <Box sx={{ mt: 1 }} className={classes.categoryAndTime}>
                <Box className={classes.categorySubcategory}>
                  {answers?.category} / {answers?.subcategoryname}
                </Box>
                {answers?.avgRating && (
                  <Box>
                    <Box
                      sx={{
                        color: "#EB5757",
                        minWidth: "50px",
                        fontSize: "15px",
                        lineHeight: "1.33",
                        padding: "2px 4px",
                        borderRadius: "2px",
                      }}
                    >
                      <StarRateIcon
                        sx={{
                          fontSize: "14px",
                          marginTop: "2px",
                          verticalAlign: "text-top",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          textAlign: "right",
                          whiteSpace: "nowrap",
                          fontWeight: 600,
                        }}
                      >
                        {answers?.avgRating?.toFixed(1)}
                      </span>
                    </Box>
                  </Box>
                )}
                <Box className={classes.answerTime}>{timeStampToTime(answers?.updatedAt)}</Box>
              </Box>
            </Box>
          ))}
        {userAnswers && userAnswers.length === 0 && (
          <Box sx={{ display: "grid", placeItems: "center", minHeight: "70vh" }}>
            <Box className={classes.NotAnswerQuestion} key={null}>
              <Box className={classes.question}>Not answered to any questions </Box>
            </Box>
          </Box>
        )}
      </Box>
      {
        userAnswers && userAnswers.length > 10 && (
          <Paging showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={userAnswers?.length} />
        )
      }
    </>
  );
}
