import thunk from "redux-thunk";
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

import masterReducer from "./master-reducer";
import userReducer from "./user-reducer";
import topicReducer from "./topic-reducer";
import questionReducer from "./question-reducer";
import raceReducer from "./race-reducer";
import countryReducer from "./country-reducer";

const rootReducer = combineReducers({
  masterReducer,
  userReducer,
  questionReducer,
  topicReducer,
  raceReducer,
  countryReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
