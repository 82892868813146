import { SERVER_URL } from '../App.config';
import { request } from '../utils/axios';

const createQuestion = (queData, userID) => {
  let data = {
    title: queData.title,
    category: queData.category.categoryid,
    subcategory: queData.subcategory.subcategoryid,
    description: queData.description,
    author: userID,
    media: queData.media,
  }
  return request(SERVER_URL + '/questions', 'post', data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const deleteQuestion = (queStatus, queID) => {
  return request(SERVER_URL + `/questions/${queID}`, 'delete', { questionId: queID })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

const updateQuestion = (queData, queID) => {
  let data = {
    title: queData.title,
    category: queData.category.categoryid,
    subcategory: queData.subcategory.subcategoryid,
    description: queData.description,
    media: queData.media,
    id: queID
  }
  console.log("calling updateQuestion");
  return request(SERVER_URL + `/questions/${queID}`, 'put', data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const currentQuestion = (questionId) => {
  if (questionId) {
    return request(SERVER_URL + `/questions/${questionId}`, 'get')
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }
};

const getAllQuestionsMedia = () => {
  return request(SERVER_URL + '/questions/getQuestionMediaForUser', 'post')
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

const getAllQuestions = () => {
  return request(SERVER_URL + '/questions/getIntialQuestionForUser', 'get')
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function searchQuestion(searchTerm) {
  return request(SERVER_URL + '/questions/searchQuestion', 'post', { searchTerm })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

const getSimilarQuestions = (title, numberOfExamples) => {
  return request(SERVER_URL + '/questions/similar', 'post', { title, numberOfExamples })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

const getQuestionCategory = (title) => {
  return request(SERVER_URL + '/questions/category', 'post', { title })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export const getUserAllQuestion = (userID) => {
  return request(SERVER_URL + '/questions/getAllQuestions', 'post', {})
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const setUserAnswerResponse = (userId, value, currentQuestion) => {
  // log user's asnwert either insert or update in same API
  return request(SERVER_URL + '/answers/captureAnswerForQuestion', 'post', { questionId: currentQuestion.id, userId, answer: value })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const skipRemindQuestion = (queId, userId, type) => {
  return request(SERVER_URL + `/questions/${queId}/captureReportQuestion`, 'post', { questionId: queId, userId, type })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const reportQuestion = (queId, userId, type, value) => {
  return request(SERVER_URL + `/questions/${queId}/captureReportQuestion`, 'post', { questionId: queId, userId, type, value })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};
export const currentCategory = (categoryId, questionId, userId) => {
  // get current category data where authorid should not be current loggedin userid
  return request(SERVER_URL + '/questions/getRelatedQuestions', 'post', { categoryId, userId, questionId })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const getUsersAnswers = () => {
  return request(SERVER_URL + '/answers/getAllQuestionAnswers', 'post', {})
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const getQuestionBasedOnOption = (selectedOption, userID) => {
  let bodyFormData = {
    categoryId: selectedOption.categoryId,
    subcategoryId: selectedOption.subcategoryId,
    userId: userID
  }
  return request(SERVER_URL + '/questions/getQuestionByFilters', 'post', bodyFormData)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const userQuestionResponse = (queID, userID) => {
  // get user's response for specific question
  let bodyFormData = {
    questionId: queID,
    userId: userID
  }
  return request(SERVER_URL + '/answers/getAnswerForSpecificQuestion', 'post', bodyFormData)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const getReportDataForQuestion = (questionId) => {
  return request(SERVER_URL + '/answers/getReportsForQuestions', 'post', { questionId })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export const QuestionApis = {
  createQuestion,
  updateQuestion,
  getAllQuestionsMedia,
  getAllQuestions,
  currentQuestion,
  deleteQuestion,
  searchQuestion,
  getSimilarQuestions,
  getQuestionCategory,
  getUserAllQuestion,
  setUserAnswerResponse,
  skipRemindQuestion,
  reportQuestion,
  currentCategory,
  getUsersAnswers,
  getQuestionBasedOnOption,
  userQuestionResponse,
  getReportDataForQuestion,
}