import { SERVER_URL } from '../App.config';
import { request } from '../utils/axios';

const createRating = (userId, queId, value) => {
    // get user's response for specific question
    let bodyFormData = {
        questionId: queId,
        userId: userId,
        value
    }
    return request(SERVER_URL + '/ratings', 'post', bodyFormData)
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

const updateRating = (ratingId, value) => {
    let bodyFormData = {
        value,
        ratingId: ratingId
    }
    return request(SERVER_URL + `/updateRating/${ratingId}`, 'put', bodyFormData)
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

const getQuestionRating = (questionID, userID) => {
    // get user's rating for specific question
    let bodyFormData = {
        questionId: questionID,
        userId: userID
    }
    return request(SERVER_URL + '/ratings/getRatingByFilter', 'post', bodyFormData)
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
}

const setRatingAverage = (queId) => {
    let bodyFormData = {
        questionId: queId
    }
    return request(SERVER_URL + `/questions/${queId}/avg-rating`, 'get')
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
};

export const RatingApis = {
    createRating,
    updateRating,
    getQuestionRating,
    setRatingAverage,
}